<template>
  <b-container fluid="">
    <b-row>
      <b-col class="mb-4"
      ><h1 class="main-header">{{ $t('Galleries') }}</h1></b-col
      >
      <b-col></b-col>
    </b-row>
    <gallery-page-tabs 

        :type="activeType"
    />
<!--
    <app-loader-centered v-if="loadingGall" />
-->
  </b-container>
</template>

<script>
import GalleryPageTabs from '@/components/GalleryPageTabs';
import awsapi from '@/lib/dynamodb';
import { mapState } from 'vuex';
import { GALLERY_TYPE } from '@/services/GalleryService';
// import AppLoaderCentered from '../components/AppLoaderCentered.vue';

export default {
  name: 'GalleriesPage',
  components: { GalleryPageTabs },
  data() {
    return {
      loading: false,
      loadingGalleries: true,
      loadingAgent: true,
    };
  },
  computed: {
    ...mapState({
                  langpref: (state) => state.Main.lang,
                  activeType: (state) => state.Galleries.activeType || GALLERY_TYPE.AG,
                  user: (state) => state.Auth.authData,
                  loadingGall: (state) => state.Galleries.loading,
                  loadingAg: (state) => state.Agent.loading,
                }),
  },
  methods: {
    setLang(l) {
      let obj = {
        id: 'agent-' + this.user.agent,
        sort: 'language',
        name: l,
      };
      awsapi.updateData(obj);
      this.$store.dispatch('Main/changeLang', l, { root: true });
    },
  },
  created() {

  },
};
</script>

<style scoped></style>

<template>
  <b-card
      class="m-2 gallery-card"
      @click="onClick"
  >
    <b-card-text class="card-new-text">
      <div class="text-center font-weight-bold m-3">
        <b-icon icon="plus-circle" class="add-new-icon" style="width: 75px; height: 75px;" aria-hidden="true"></b-icon>
      </div>
      <h4 class="text-center font-weight-bold add-new-icon">{{ title }}</h4>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: 'NewCard',
  props: {
    title: String,
    onClick: Function,
  },
}
</script>

<style scoped>

.gallery-card {
    flex-basis: 30%;
    max-height: 450px;
    border: none;
    margin: 1rem 0 2rem !important;
    border: 3px dashed #296ED6 !important;
    min-height: 209px !important;
    max-height: 209px !important;
}

.card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-new-text {
    position: absolute;
    /* top: 50%;
    transform: translateY(-66%); */
}

</style>
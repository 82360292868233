<template>
  <b-modal 
      :id="id"
      size="sm"
      :title="`New Photo Gallery`"
      @close="isShow=false"
      static
      v-model="isShow"
      header-text-variant=""
      ok-title="Create"
      @ok="handleOk"
      hide-header-close
      header-class="modal-header-left"
  >

    <template #default>
      <b-form>
        <b-row>
          
          <b-col cols="12">
            <b-form-group
                id="input-group-1"
                description=""
                class="input-group-1"
            >
              <b-form-input
                  id="input-1"
                  name="input-1"
                  type="text"
                  :placeholder="$t('Enter New Gallery Name')"
                  required
                  v-model="$v.form.gallery_name.$model"
                  :state="validateState('gallery_name')"
                  aria-describedby="input-1-live-feedback"
              ></b-form-input>
              <b-form-invalid-feedback :state="validateState('gallery_name')"
                                       id="input-1-live-feedback"
              >{{$t('This is a required field and must be at least 3 characters')}}.
              </b-form-invalid-feedback>
            </b-form-group>

            <div v-if="type===$options.galleryTypes.ML">
              <b-form-group :label="$t('Property Address')" label-for="gallery_prop_address">
                <b-form-input
                    v-model="form.gallery_prop_address"
                    :placeholder="$t('Property Address')+'...'"
                    id="gallery_prop_address"
                    :state="validateState('gallery_prop_address')"
                    aria-describedby="gallery_prop_address-live-feedback"
                ></b-form-input>
                <b-form-invalid-feedback :state="validateState('gallery_prop_address')"
                                         id="gallery_prop_address-live-feedback"
                >{{$t('Please enter a property address')}}.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group :label="$t('Property City')" label-for="gallery_prop_city">
                <b-form-input v-model="form.gallery_prop_city" :placeholder="$t('Property City')+'...'"
                              id="gallery_prop_city"></b-form-input>

              </b-form-group>

              <b-form-group :label="$t('Property State')" label-for="gallery_prop_state">
                <b-form-input v-model="form.gallery_prop_state" :placeholder="$t('Property State')+'...'"
                              id="gallery_prop_state"></b-form-input>
              </b-form-group>
            </div>


          </b-col>

        </b-row>
      </b-form>
    </template>

    <template #modal-footer>
      <b-button variant="light" @click="isShow=false">{{$t('Cancel')}}</b-button>
      <b-button variant="primary" @click="handleOk" :disabled="loading" class="create-btn btn-dark">
        <span v-if="!loading">{{ isUpdate ? $t('Save') : $t('Create') }}</span>
        <b-spinner small v-if="loading" />
      </b-button>
    </template>

  </b-modal>
</template>

<script>

//       <!--:title="`${$t(`${isUpdate ? 'Update' : 'New'} Photo ${$options.galleryTypeNames[type][0].toUpperCase()}${$options.galleryTypeNames[type].slice(1)} Gallery`)}`" -->

import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { GALLERY_TYPE, GALLERY_TYPE_NAME } from '@/services/GalleryService';
import { mapState } from 'vuex';

export default {
  mixins: [validationMixin],
  name: 'NewGalleryModal',
  props: {
    id: String,
    show: Boolean,
    onClose: Function,
    onCreate: Function,
    loading: Boolean,
    type: String,
    gallery: {
      type: Object, default() {
        return {}
      },
    },
    isUpdate: { type: Boolean, default: false },
  },
  galleryTypeNames: GALLERY_TYPE_NAME,
  galleryTypes: GALLERY_TYPE,
  data() {
    return {
      // loading: false,
      isShow: false,
      form: {
        gallery_name: '',
        gallery_type: GALLERY_TYPE.AG,
        gallery_prop_city: '',
        gallery_prop_address: '',
        gallery_prop_state: '',
      },

    }
  },
  validations() {
    const base = {
      gallery_name: {
        required,
        minLength: minLength(3),
      },
    };

    const ml = {
      gallery_prop_address: {
        required,
        // minLength: minLength(3),
      },
    };

    return { form: { ...base, ...(this.type === GALLERY_TYPE.ML) ? ml : {} } };

  },
  computed: {
    ...mapState({
                  langpref: state => state.Main.lang,
                }),
  },
  mounted() {
    // this.form.gallery_name = '';
    this.resetForm();
    this.form.gallery_type = this.type || GALLERY_TYPE.AG;
    if (this.gallery) {
      this.setGalleryData(this.gallery);
    }
    // this.$v.$reset();
  },
  watch: {
    gallery(newGalleryProp) {
      this.setGalleryData(newGalleryProp);
    },
    isShow(newValue) {
      if (!newValue) {
        this.$v.$reset();
        this.$emit('onClose', this.form);
      } else {
        this.setGalleryData(this.gallery);
        this.$v.$reset();
      }
    },
    show(newValue) {
      this.isShow = newValue;
    },
    type(newType) {
      this.form.gallery_type = newType;
    },
  },
  methods: {
    setGalleryData(newGalleryProp) { //
      this.resetForm();
      if (newGalleryProp && Object.keys(newGalleryProp).length) {
        this.form = { ...newGalleryProp };
      }
    },
    validateState(name) {
      if (!(name in this.$v.form)) return null;

      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    resetForm() {
      this.form = {
        gallery_name: '',
        gallery_prop_address: '',
        gallery_prop_city: '',
        gallery_prop_state: '',
        // gallery_type: GALLERY_TYPE.AG,
      };

      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
    handleOk(bvModalEvent) {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        bvModalEvent.preventDefault()
        return;
      } else {
        this.$emit('onCreate', this.form);
      }

      bvModalEvent.preventDefault()
    },
  },
}
</script>

<style lang="scss">

.input-group-1 label{
  font-size: 40px;
  margin-bottom: 40px;
  font-weight: normal; 
}

.gallery-wrap .modal-footer {
  border-top: none !important;
}

.gallery-wrap .form-control {
  font-size: 16px !important;
}



/* .create-btn {
  margin: 4px 4px 5px 4px !important;
} */

</style>
<template>
  <div class="wrapper-spinner">
    <b-spinner class="spinner" />
  </div>
</template>

<script>
export default {
  name: "AppLoaderCentered",
  props: {},
};
</script>
<style scoped lang="scss">
.wrapper-spinner {
  opacity: 0.5;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 250px;

  position: absolute;
  top: 0;
  left: 0;
}
.spinner {
  width: 4rem;
  height: 4rem;
  font-weight: bold;
}


</style>

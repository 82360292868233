
import { v4 as uuidv4 } from 'uuid';


const randomSize = () => {
    const min = 200;
    const max = 4000;
    return Math.floor(Math.random() * (max - min) + min);
};

const filenameFromUrl = (f) => {
    let filename = '';
    let fileurla = f.split('/');
    if (fileurla.length) {
        filename = fileurla[fileurla.length - 1];
    }
    if (!filename || filename == '') return '';
    return filename;
};

const queryStringParse = (k) =>{
    let ret = {};

    let a = location.href.split('?');
    if (a.length > 1) {
        let query = a[1];
        // let a2 = [0];
        if (query.indexOf('#') != -1) {
            query = query.split('#')[0];
        }
        // console.log(query);
        a = query.split('&');
        for (let b in a) {
            // console.log('b', b);
            let a2 = a[b].split('=');
            ret[a2[0]] = a2[1];
        }
        console.log('query', ret);
    }

    if (k) {
        if (ret[k]) return ret[k];
        return '';
    }

    return ret;
}

const galleryNameFromProperty = (gt, gn, addr, city, st) => {
    if (gt != 'ML' || !addr) return gn;
    let s = addr;
    if (city != '') s += ', ' + city;
    if (st != '') s += ', ' + st;
    return s;
};

const galleryName = (d) => {
    if (d.gallery_type != 'ML') return d.gallery_name;
    const s = galleryNameFromProperty(
        d.gallery_type,
        d.gallery_name,
        d.gallery_prop_address,
        d.gallery_prop_city,
        d.gallery_prop_state
    )
    return s;
};


const dateStamp = (dstr) => {
    let d = new Date();
    if (dstr) {
        let dstrf = dstr;
        if (dstr.length == 8) {
            // dstrf = dstr.substr(0, 4) + '-' + dstr.substr(4, 2) + '-' + dstr.substr(6, 2);
            return dstr;
        } // else assume it has a long actual timestamp format that can be converted
        d = new Date(dstrf);
    }
    const y = d.getFullYear();
    let m = d.getMonth() + 1;
    let da = d.getDate();
    if (m < 10) m = "0" + m;
    if (da < 10) da = "0" + da;
    return ('' + y + m + da);
};

const guid = (str) => {
    const g = uuidv4();
    if (str) return str + '-' + g;
    return g;
};

const gallery = (d, sessionData) => {
    console.log('gallery format input', d);
    let dt = dateStamp(d.created_at);
    // if (d.created_at) dt = d.created_at;
    let pk = 'agent-' + sessionData.agent; // "agent-002100-0101", "office-002100", "company-2100"
    let pk2 = d.gallery_type + '-' + d.id;
    let sk = d.gallery_type + '-' + dt + '-' + d.id;
    if (d.sk) sk = d.sk;
    let gn = galleryName(d);
    let gallery_id = d.id;
    switch (d.gallery_type) {
        case 'OF':
            pk = 'office-' + sessionData.office;
            break;
        case 'CO':
            pk = 'company-' + sessionData.company;
            break;
    }
    let gal = {
        id: pk,
        sort: sk,
        gallery_type: d.gallery_type,
        gallery_id: d.id,
        name: gn,
        created_at: dt,
        thumb: d.thumb || '',
    };
    let info = {
        id: pk2,
        sort: "info",
        gallery_type: d.gallery_type,
        created_at: dt,
        name: gn
    };
    let photos = [];
    const m = gallery_id.indexOf('-');
    switch (gal.gallery_type) {
        case 'AG':
            info.agent_owner = sessionData.agent;
            break;
        case 'ML':
            info.mls_source = gallery_id.substr(0, m);
            info.mls_id = gallery_id.substr(m + 1);
            info.prop_address = d.gallery_prop_address;
            info.prop_city = d.gallery_prop_city;
            info.prop_state = d.gallery_prop_state;
            break;
    }
    if (d.photos) {
        d.photos.forEach((ph, idx) => {
            photos.push({
                id: (ph.pk ? ph.pk : d.gallery_type + '-' + d.id), // gallery id
                sort: (ph.sk ? ph.sk : 'photo-' + dt + '-' + (idx + 1)),
                created_at: (ph.created_at ? ph.created_at : dt),
                source_system: (ph.source_system ? ph.source_system : (d.gallery_type == 'ML' ? info.mls_source : 'Upload')),
                src: ph.src,
                height: (ph.height ? ph.height : 1),
                width: (ph.width ? ph.width : 1)
            });
        });
    }
    // console.log('d', d);
    // console.log('g', gal);
    // console.log('i', info);
    // console.log('p', photos);
    return { gal, info, photos };
};

const photo = (d) => {
    // console.log('photo format this data',d);
    let dt = dateStamp();
    const _guid = guid();
    /**
     * d = {
     *  pk, sk, gallery_type, gallery_id, created_at, source_system, src, thumb
     * }
     */
    let photo = {
        id: (d.pk ? d.pk : d.gallery_type + '-' + d.gallery_id),
        sort: (d.sk ? d.sk : 'photo-' + dt + '-' + _guid),
        src: d.src,
        created_at: (d.created_at ? d.created_at : dt),
        source_system: (d.source_system ? d.source_system : 'Upload'),
        thumb: (d.thumb ? d.thumb : '')
    };
    return photo;
};

// transform a gallery photo into an order photo
const orderPhoto = (d, orderid) => {
    // console.log('photo format this data',d);
    let dt = dateStamp();
    // const _guid = guid();
    /**
     * d = {
     *  pk, sk, photovar, created_at, source_system, src, thumb
     * }
     */
    // if (d.pk && d.pk.indexOf('order-') == 0) {
    //     console.log('util.orderPhoto already an order photo',d);
    //     return d;
    // }
    if (!d.photovar) {
        console.log('util.orderPhoto photo needs a photovar',d);
        return false;
    }
    let photo = {
        id: ('order-' + orderid),
        sort: ('photo-' + d.photovar),
        name: d.name,
        src: d.src,
        created_at: (d.created_at ? d.created_at : dt),
        source_system: (d.source_system ? d.source_system : 'Upload'),
        thumb: (d.thumb ? d.thumb : '')
    };
    return photo;
}

export default {
    randomSize,
    filenameFromUrl,
    queryStringParse,
    dateStamp,
    gallery,
    photo,
    orderPhoto,
    guid,
    galleryNameFromProperty,
    galleryName
}


<template>
  <b-container fluid="">
    <h1 class="title-container">
      <b-button
          variant="outline  "
          v-if="!isTitleEdit"
          v-show="isEditable && !isOrderMode"
          @click="openGalleryEditor"
      >
        <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.751574 15.253L0.890582 14.4562L1.54872 15.1144L0.755259 15.2535C0.75505 15.2536 0.754851 15.2536 0.754661 15.2536C0.753632 15.2538 0.752871 15.2539 0.752353 15.2539C0.752128 15.2537 0.751844 15.2535 0.751517 15.2533L0.751574 15.253ZM12.0896 6.85496L5.00024 13.9446V13.2521V12.5021H4.25024H3.50024V11.752V11.2831C3.96438 11.614 4.6106 11.571 5.02745 11.1542L9.83995 6.34149C10.3047 5.87672 10.3047 5.12685 9.83995 4.66208C9.53403 4.35614 9.10458 4.25158 8.71422 4.34839L9.14867 3.91392L12.0896 6.85496ZM3.03435 10.0285C2.95271 10.3577 3.01431 10.7148 3.21914 11.002H2.75024H2.06085L3.03435 10.0285ZM0.0129385 15.1241L0.0127361 15.124L0.0129385 15.1241ZM15.0293 2.85073L15.0307 2.85214C15.3226 3.14254 15.3247 3.61663 15.0293 3.91205L13.8534 5.088L10.914 2.14852L12.09 0.972525C12.3804 0.682015 12.8562 0.679787 13.1519 0.973321C13.1521 0.973526 13.1524 0.973732 13.1526 0.973937L15.0293 2.85073Z" fill="#525151" stroke="#525151" stroke-width="1.5"/>
        </svg>



      </b-button>
      <span v-if="!isTitleEdit" class="main-header">
        {{
          loading
              ? `${$t('Loading')}...`
              : activeGallery
                  ? activeGallery.gallery_name
                  : ''
        }}
      </span>

      <b-input-group
          prepend=""
          size="lg"
          class="edit-title"
          v-show="isTitleEdit"
          ref="input"
      >
        <b-form-input
            :disabled="saveLoading"
            size="lg"
            v-model="updatedTitle"
        ></b-form-input>
        <b-input-group-prepend>
          <b-button v-if="saveLoading" variant="outline-success" disabled>
            <b-spinner small />
          </b-button>
          <template v-else>
            <b-button class="title-change-btn" variant="outline-success" size="lg" @click="saveGallery">
              <b-icon icon="check" />
            </b-button>
            <!-- <b-button
                variant="outline-danger"
                size="lg"
                @click="titleEditClose"
            >
              <b-icon icon="x" />
            </b-button> -->
          </template>
        </b-input-group-prepend>
      </b-input-group>
    </h1>

   
    <b-row v-if="this.loading">
      <b-col cols="12" class="text-center">
        <b-spinner style="width: 4rem; height: 4rem; font-weight: bold" />
      </b-col>
    </b-row>
    <images-list-box
        v-if="activeGallery && !loading"
        :galleryType="type"
        :galleryId="id"
        :gallery="activeGallery"
        :list="activeGallery.photos || []"
        @onOpenNew="handleOpenNew"
        :is-editable="isEditable"
        :is-only-select="isOrderMode"
    />

     <b-row class="mt-6 mb-5">
      <b-col>
        <b-button
            class="mr-4 pl-3 pr-3"
            variant="primary"
            @click="goBack()"
        >
          <span class="back-btn"> {{ $t('Back To Galleries') }}</span>
        </b-button>
      </b-col>

      <b-col class="text-right" v-if="!isOrderMode">
        <b-btn-group>
          <!-- temporally disabled -->
          <!--
          <b-button variant="success">
            <b-icon icon="cloud-arrow-down"></b-icon>
            {{$t('Export Gallery')}}
          </b-button>
          -->
          <b-button
              v-if="isEditable"
              variant="primary"
              @click="isDeleteShow = true"
              class="delete-btn pl-3 pr-3 d-flex align-items-center"
          >
            <!-- <b-icon icon="trash"></b-icon> -->
            {{ $t('Delete Gallery') }}
          </b-button>
        </b-btn-group>
      </b-col>
    </b-row>



    <h1
        v-show="!isEditable"
        v-if="
        !loading &&
          (!activeGallery ||
            !activeGallery.photos ||
            !activeGallery.photos.length)
      "
        class="text-center"
    >
      {{ $t('No Images') }}
    </h1>
    <delete-modal
        :show="isDeleteShow"
        :delete-id="{}"
        :title="$t('Delete Gallery?')"
        :loading="saveLoading"
        @on-close="isDeleteShow = false"
        @on-ok="handleDeleteGalleryConfirmed"
        id="deleteGalleryWnd"
        header-class="modal-header-left"
    >
      <template #default>
        <p class="text-left mb-3">
          {{
            $t(
                'Are you sure you want to permanently delete this gallery?',
            )
          }}
        </p>
      </template>
    </delete-modal>

    <new-gallery-modal
        id="edit-ml-gallery-wnd"
        :type="activeType"
        :gallery="activeGallery"
        :show="isEditMlGalleryWndOpen"
        @onCreate="handleUpdateMlGallery"
        @onClose="closeEditMlGalleryWnd"
        :loading="saveLoading || loading"
        :is-update="true"
    />
  </b-container>
</template>

<script>
import vClickOutside from 'v-click-outside';
import { mapState } from 'vuex';
import ImagesListBox from '@/components/ImagesListBox';
import DeleteModal from '@/components/DeleteModal';
import { GALLERY_TYPE } from '@/services/GalleryService';
import NewGalleryModal from '@/components/NewGalleryModal';

const { bind, unbind } = vClickOutside.directive;

export default {
  name: 'SingleGalleryPage',
  components: { ImagesListBox, DeleteModal, NewGalleryModal },
  params: {
  },
  computed: {
    ...mapState({

                  activeGallery: state => state.Galleries.activeGallery,
                  // activeGalleryId: state => state.Galleries.activeGalleryId,
                  activeType: state => state.Galleries.activeType,
                  loading: state => state.Galleries.loading,
                  saveLoading: state => state.Galleries.saveLoading,
                  permissions: state => state.Auth.permissions,
                  user: state => state.Auth.authData,
                  isOrderMode: state => state.Main.isOrderMode,
                  selectedImages: state => state.ImageSelection.selectedImages,
                  selectedImageId: state => state.ImageSelection.setSelectedId,
                }),
    isEditable() {
      // if (!this.activeGallery || !this.activeGallery.gallery_type) return false;
      return !!this.permissions[this.type];
    },
    selectedPhotoId() {
      if (this.selectedImageId) return this.selectedImageId;

      return this.selectedImages && this.selectedImages.length ? this.selectedImages[0].id : null;
    },
  },
  data() {
    return {
      isTitleEdit: false,
      updatedTitle: '',
      id: null,
      type: '',
      isDeleteShow: false,

      isEditMlGalleryWndOpen: false,

    };
  },
  mounted() {
    this.type = this.activeType;
    this.id = this.activeGallery.id;

    // const selectedPhotoId = false; // this.$route.params.selectedPhotoId;
    if (
        this.user &&
        (this.activeGallery && !this.activeGallery.photos)
    ) {
      this.$store.dispatch('Galleries/loadGallery', {
        type: this.type,
        id: this.id,

        selectedPhotoId: this.selectedPhotoId,
      });
    } else {
      if (this.selectedPhotoId) {
        // console.log(this.activeGallery.photos);
        const selectedImage = this.activeGallery.photos.find(
            i => i.pk === this.selectedPhotoId,
        );
        if (selectedImage)
          this.$store.dispatch('ImageSelection/toggleImage', selectedImage, {
            root: true,
          });
      }
    }
  },
  methods: {
    goBack() {
      this.$store.commit('Galleries/setActiveGallery', null);
    },
    handleOpenNew() {
      this.$store.commit('Main/setIsUploadPage', true);

    },
    closeEditor() {
      if (this.isTitleEdit) {
        this.isTitleEdit = false;
      }
    },
    titleEditClose() {
      this.isTitleEdit = false;
    },
    async saveGallery(newGallery) {
      if (
          !newGallery &&
          this.updatedTitle === this.activeGallery.gallery_name
      ) {
        this.titleEditClose();
        return;
      }

      if (this.updatedTitle || newGallery) {
        try {
          const updatedGal = {
            ...this.activeGallery,

            gallery_name: this.updatedTitle,
          };
          await this.$store.dispatch('Galleries/updateGalleryName', updatedGal);

          this.$toast.success(this.$t('Gallery was updated successfully'));
          setTimeout(() => {
            this.$store
                .dispatch('Galleries/loadGalleriesByType', {
                  type: this.type,
                  isReload: true,
                })
                .then()
                .catch(() => {
                  console.error('gallery reload error');
                });
          }, 500);
        } catch (e) {
          this.$toast.error(this.$t('Gallery was not updated'));
        }
        this.titleEditClose();
      }
    },
    async handleDeleteGalleryConfirmed() {
      await this.$store.dispatch(
          'Galleries/deleteGallery',
          { galleryId: this.id, type: this.type },
          { root: true },
      );
      this.isDeleteShow = false;
      this.goBack();
    },
    handleDeleteGallery() {
      this.isDeleteShow = true;
    },
    openGalleryEditor() {
      if (this.activeType !== GALLERY_TYPE.ML) {
        this.isTitleEdit = true;
      } else {
        this.isEditMlGalleryWndOpen = true;
      }
    },
    closeEditMlGalleryWnd() {
      this.isEditMlGalleryWndOpen = false;
    },
    async handleUpdateMlGallery(newGallery) {
      await this.saveGallery(newGallery);
      this.closeEditMlGalleryWnd();
    },
  },

  watch: {
    selectedImageId(){

    },

    activeGallery(loadedGallery, prevGallery) {
      if (loadedGallery === prevGallery) {
        return;
      }


      if (loadedGallery)
          // activeGallery
        if (
            this.type !== GALLERY_TYPE.ML &&
            loadedGallery.id === this.id &&
            (!loadedGallery.thumb || !loadedGallery.thumb) &&
            Array.isArray(loadedGallery.photos) &&
            loadedGallery.photos.length
        ) {
          const thumbs = this.activeGallery.photos
              .map(_ => _.thumb)
              .filter(_ => _.length);
          if (thumbs.length) {
            const [firstThumb] = thumbs;
            if (firstThumb) {
              this.$store
                  .dispatch('Galleries/updateActiveGalleryThumb', firstThumb, {
                    root: true,
                  })
                  .then(async () => {
                    await this.$store.dispatch('Galleries/loadGalleriesByType', {
                      type: this.type,
                      isReload: true,
                    });
                  });
            }
          }
        }
    },
    isTitleEdit(newStat) {
      if (newStat) {
        bind(this.$refs.input, { value: this.closeEditor });
        this.updatedTitle = this.activeGallery.gallery_name;
      } else {
        unbind(this.$refs.input);
        this.isTitleEdit = '';
      }
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
};
</script>

<style lang="scss">
.modal-title {
  font-size: 22px;
}

.mt-6 {
  margin-top: 2rem;
}

.delete-btn {
  font-size: 1.25rem;
}

.delete-btn, .back-btn {
  background: transparent !important;
  height: 38px;
  font-size: 18px !important;
  color:  #fff;
}

.edit-title {
  max-width: 400px;
  border: 1px solid #666666;
  border-radius: 3px;
}

.title-change-btn {
  svg {
    
  }
}

.title-container {
  position: relative;
  width: fit-content;
  padding-right: 50px;
  
  button {
    position: absolute;
    right: 0;
    position: absolute;
        top: 50%;
        transform: translateY(-50%);
  
  }
}

</style>

<template>
  <div style="">
    <b-button v-if="isEditable" size="lg" variant="primary" class="float-right ml-4" @click="createNew">Create New</b-button>
    <gallery-search-input class="float-right" style="width: 243px" @on-change="runSearch" />

  </div>
</template>

<script>

import GallerySearchInput from '@/components/SearchInput';

export default {
  name: 'SearchCreatePanel',
  components: { GallerySearchInput },
  props: {
    runSearch: Function,
    createNew: Function,
    isEditable: Boolean,
    isContent: Boolean,
  },
}
</script>

<style scoped>

</style>
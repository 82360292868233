import Vue from 'vue';

const translations = {
  Galleries: { es: 'Galerías', fr: 'Galeries' },
  'My Photos': { es: 'Mis Fotos', fr: 'Mes Photos' },
  Local: { es: 'Local', fr: 'Local' },
  Companies: { es: 'Compañías', fr: 'Entreprises' },
  Company: { es: 'Empresa', fr: 'Compagnie' },
  Listings: { es: 'Listados', fr: 'Annonces' },
  'Select a Gallery': {
    es: 'Seleccione una Galería',
    fr: 'Sélectionnez une galerie',
  },
  'Gallery Name': { es: 'Nombre de la galería', fr: 'Nom de la galerie' },
  New: { es: 'Nuevo', fr: 'Nouveau' },
  'New Photo Gallery': {
    es: 'Nueva galería de fotos',
    fr: 'Nouvelle galerie de photos',
  },
  'New Local Gallery': {
    es: 'Nueva galería local',
    fr: 'Nouvelle galerie locale',
  },
  'New Company Gallery': {
    es: 'Galería de nueva empresa',
    fr: 'Nouvelle galerie d\'entreprise',
  },
  'New Listing Gallery': {
    es: 'Galería de nuevos listados',
    fr: 'Nouvelle galerie d\'annonces',
  },
  'Property Address': {
    es: 'Dirección de Propiedad',
    fr: 'Adresse de la propriété',
  },
  'Property City': { es: 'Ciudad de la propiedad', fr: 'Propriété Ville' },
  'Property State': {
    es: 'Estado de la propiedad',
    fr: 'État de la propriété',
  },
  Upload: { es: 'Subir', fr: 'Télécharger' },
  Next: { es: 'Próximo', fr: 'Prochain' },
  Prev: { es: 'Previo', fr: 'Avant' },
  Photos: { es: 'Fotos', fr: 'Photos' },
  rename: { es: 'rebautizar', fr: 'Renommer' },
  Change: { es: 'Cambio', fr: 'Changement' },
  Cancel: { es: 'Cancelar', fr: 'Annuler' },
  Delete: { es: 'Eliminar', fr: 'Supprimer' },
  Continue: { es: 'Seguir', fr: 'Continuer' },
  'Apply Photos': { es: 'Aplicar fotos', fr: 'Appliquer des photos' },
  'Product Photos': { es: 'Fotos del producto', fr: 'Photos de produits' },
  'Click a product photo box, then click a gallery photo to assign it. When all photos have been assigned, click "Apply Photos" to continue.': {
    es:
      'Haga clic en el cuadro de una foto de producto, luego haga clic en una foto de la galería para asignarla. Cuando se hayan asignado todas las fotos, haga clic en "Aplicar fotos" para continuar.',
    fr:
      'Cliquez sur une boîte de photo de produit, puis sur une photo de galerie pour l\'attribuer. Une fois toutes les photos attribuées, cliquez sur "Appliquer les photos" pour continuer.',
  },
  'You\'re doing great!...': {
    es: '¡Lo estás haciendo genial!...',
    fr: 'Tu le fais bien!...',
  },
  'You\'re all set! Click "Apply Photos" to continue.': {
    es: '¡Estás listo! Haga clic en "Aplicar fotos" para continuar.',
    fr: 'Vous êtes prêt! Cliquez sur «Appliquer les photos» pour continuer.',
  },
  'This product does not have photos.': {
    es: 'Este producto no tiene fotos.',
    fr: 'Ce produit n\'a pas de photos.',
  },
  'Please enter a property address.': {
    es: 'Introduzca una dirección de propiedad.',
    fr: 'Veuillez saisir une adresse de propriété.',
  },
  'Please enter a gallery name.': {
    es: 'Introduzca un nombre de galería.',
    fr: 'Veuillez saisir un nom de galerie.',
  },
  Create: { es: 'Crear', fr: 'Créer' },
  Page: { es: 'Página', fr: 'Page' },
  'Generating Thumb': {
    es: 'Generando miniatura',
    fr: 'Générer une miniature',
  },
  'Enter New Gallery Name': {
    es: 'Ingrese el nuevo nombre de la galería',
    fr: 'Entrez le nouveau nom de la galerie',
  },
  'New Gallery Name': {
    es: 'Nuevo nombre de la galería',
    fr: 'Nouveau nom de galerie',
  },
  'New Agent Gallery': {
    es: 'Galería de nuevos agentes',
    fr: 'Galerie du nouvel agent',
  },
  'My Gallery': { es: 'Mi galería', fr: 'Ma galerie' },
  'Office Gallery': { es: 'Galería de oficinas', fr: 'Galerie de bureau' },
  'Company Gallery': {
    es: 'Galería de la empresa',
    fr: 'Galerie de l\'entreprise',
  },
  'My MLS Gallery': { es: 'Mi galería MLS', fr: 'Ma galerie MLS' },
  'Back To Galleries': {
    es: 'Volver a las galerías',
    fr: 'Retour aux galeries',
  },
  Loading: { es: 'Cargando', fr: 'Chargement' },
  'Export Gallery': { es: 'Exportar Galería', fr: 'Galerie d\'exportation' },
  'Delete Gallery': { es: 'Eliminar galería', fr: 'Supprimer la galerie' },
  'Select an Office Gallery': {
    es: 'Seleccione una galería de Office',
    fr: 'Sélectionnez une galerie Office',
  },
  'Select a Company Gallery': {
    es: 'Seleccione una galería de la empresa',
    fr: 'Sélectionnez une galerie d\'entreprise',
  },
  'Select a MLS Gallery': {
    es: 'Seleccione una galería MLS',
    fr: 'Sélectionnez une galerie MLS',
  },
  'This is a required field and must be at least 3 characters': {
    es: 'Este es un campo obligatorio y debe tener al menos 3 caracteres',
    fr: 'Ce champ est obligatoire et doit comporter au moins 3 caractères',
  },
  'Please enter a property address': {
    es: 'Ingrese la dirección de una propiedad',
    fr: 'Veuillez saisir une adresse de propriété',
  },
  Save: { es: 'Salvar', fr: 'Sauvegarder' },
  'Create New Gallery': {
    es: 'Crear nueva galería',
    fr: 'Créer une nouvelle galerie',
  },
  'Upload up to {max} Images': {
    es: 'Sube hasta {max} imágenes',
    fr: 'Téléchargez jusqu\'à {max} images',
  },
  'Click to Add Images': {
    es: 'Haga clic para agregar imágenes',
    fr: 'Cliquez pour ajouter des images',
  },
  'Limit file sizes to': {
    es: 'Limitar el tamaño de los archivos a',
    fr: 'Limiter la taille des fichiers à',
  },
  'Search gallery name': {
    es: 'Nombre de la galería de búsqueda',
    fr: 'Rechercher le nom de la galerie',
  },
  Uploading: { es: 'Subiendo', fr: 'Téléchargement' },
  'Thumbs Creating': { es: 'Creación de pulgares', fr: 'Création de pouces' },
  Reloading: { es: 'Recarga', fr: 'Rechargement' },
  'Upload Images': { es: 'Subir imágenes', fr: 'Importer des images' },
  'Back To Gallery': { es: 'Volver a la galería', fr: 'Retour à la galerie' },
  'Add New Image': {
    es: 'Agregar nueva imagen',
    fr: 'Ajouter une nouvelle image',
  },

  'Are you sure you want to Delete your Image?': {
    es: '¿Estás segura de que quieres eliminar tu imagen?',
    fr: 'Voulez-vous vraiment supprimer votre image?',
  },
  'You are about to permanently delete image': {
    es: 'Estás a punto de eliminar la imagen de forma permanente',
    fr: 'Vous êtes sur le point de supprimer définitivement l\'image',
  },
  'This is an irreversible action': {
    es: 'Esta es una acción irreversible',
    fr: 'C\'est une action irréversible',
  },
  'Please confirm you would like to permanently delete your image': {
    es: 'Confirme que desea eliminar permanentemente su imagen',
    fr:
      'Veuillez confirmer que vous souhaitez supprimer définitivement votre image',
  },
  Confirm: { es: 'Confirmar', fr: 'Confirmer' },
  'Are you sure you want to Delete this Gallery?': {
    es: '¿Estás seguro de que quieres eliminar esta galería?',
    fr: 'Voulez-vous vraiment supprimer cette galerie?',
  },
  'You are about to permanently delete your Gallery and all associated images': {
    es:
      'Está a punto de eliminar permanentemente su Galería y todas las imágenes asociadas',
    fr:
      'Vous êtes sur le point de supprimer définitivement votre galerie et toutes les images associées',
  },
  'Please confirm you would like to permanently delete your Gallery': {
    es: 'Confirme que desea eliminar permanentemente su galería',
    fr:
      'Veuillez confirmer que vous souhaitez supprimer définitivement votre galerie',
  },
  'No Images': { es: 'Sin imágenes', fr: 'Aucune image' },
  'No Galleries': { es: 'No hay galerías', fr: 'Pas de galeries' },
  'Gallery was updated successfully': {
    es: 'La galería se actualizó correctamente',
    fr: 'La galerie a été mise à jour avec succès',
  },
  'New Gallery was added': {
    es: 'Se agregó una nueva galería',
    fr: 'Une nouvelle galerie a été ajoutée',
  },
  'Images were Added successfully': {
    es: 'Las imágenes se agregaron con éxito',
    fr: 'Les images ont été ajoutées avec succès',
  },
  'New Gallery creation Error': {
    es: 'Error de creación de nueva galería',
    fr: 'Erreur de création d\'une nouvelle galerie',
  },
  'Gallery was not updated': {
    es: 'La galería no se actualizó',
    fr: 'La galerie n\'a pas été mise à jour',
  },
  'You cannot upload more then {max} files': {
    es: 'No puede cargar más de {max} archivos',
    fr: 'Vous ne pouvez pas télécharger plus de {max} fichiers',
  },
  'Image was successfully Deleted': {
    es: 'La imagen se eliminó correctamente',
    fr: 'L\'image a été supprimée avec succès',
  },
  'Select a photo from your computer or drag and drop here.': {
    es: 'Seleccione una foto de su computadora o arrastre y suelte aquí.',
    fr:
      'Sélectionnez une photo sur votre ordinateur ou faites un glisser-déposer ici.',
  },
  'There is a maximum of 20 photos, each can be up to 10MB.': {
    es: 'Hay un máximo de 20 fotos, cada una puede tener hasta 10 MB.',
    fr: 'Il y a un maximum de 20 photos, chacune pouvant aller jusqu\'à 10 Mo.',
  },
  '{f_name} exceeds the size limit of 10MB': {
    es: '{f_name} supera el límite de tamaño de 10 MB',
    fr: '{f_name} dépasse la limite de taille de 10 Mo',
  },
  // "" : {"es": "", "fr": ""},
  // "" : {"es": "", "fr": ""},
  // "" : {"es": "", "fr": ""},
};

export const getTranslationsByLang = lang => {
  return Object.keys(translations).reduce((prev, key) => {
    prev[key] = lang in translations[key] ? translations[key][lang] : key;
    return prev;
  }, {});
};

import VueI18n from 'vue-i18n';

// here
Vue.use(VueI18n);
// call with I18n option
export const i18n = new VueI18n({
                                  legacy: false,
                                  locale: 'en',
                                  fallbackLocale: 'en',
                                  messages: {
                                    en: getTranslationsByLang('en'),
                                    fr: getTranslationsByLang('fr'),
                                    es: getTranslationsByLang('es'),
                                  },
                                });

let langpref = 'en';
const getSupportedLanguages = () => {
  return [
    {
      k: 'en',
      v: 'English',
    },
    {
      k: 'es',
      v: 'Español',
    },
    {
      k: 'fr',
      v: 'Français',
    },
  ];
};

const t = (l, p) => {
  // console.log(langpref,l);
  if (p) langpref = p;
  if (langpref == 'en') return l;
  try {
    if (translations[l][langpref] && translations[l][langpref] != '')
      return translations[l][langpref];
  } catch (e) {
    console.log(l, p, e);
  }
  return l;
};
const setLang = l => {
  langpref = l;
};
export default {
  t,
  setLang,
  getSupportedLanguages,
};

// https://github.com/amazon-archives/serverless-image-resizing/blob/master/lambda/index.js
// https://github.com/legalthings/lambda-pdf-thumbnail
// https://gist.github.com/kageurufu/68667fcb6d3a08078616

// import { API } from 'aws-amplify';
import awsapi from './dynamodb';
// import awsconfig from '../aws-exports';
import dbcapi from './dbcapi';
import axios from 'axios';

/*
    {
        photo
        gallery
        status: String [pending, progress, complete, error]
        errorMessage: String
        checks: Int [number of times]
    }
*/
let thumbQueue = {};
let thumbQueueRunning = false;

const debug = () => {
    let arr = [];
    for (let i in thumbQueue) {
        arr.push({ i, t: (thumbQueue[i].status + ' ' + thumbQueue[i].checks + ' ' + thumbQueue[i].gallery.id) });
    }
    return arr;
}

const thumbLog = () => {
    let pending = 0;
    let error = 0;
    let progress = 0;
    let complete = 0;
    let unknown = 0;
    for (let i in thumbQueue) {
        // thumbQueue[i].checks++;
        switch (thumbQueue[i].status) {
            case 'pending':
                pending++;
                break;
            case 'progress':
                progress++;
                break;
            case 'complete':
                complete++;
                break;
            case 'error':
                error++;
                break;
            default:
                unknown++;
        }
    }
    console.log(`pe${pending} > pr${progress} > c${complete} e${error} u${unknown} `);
};

const thumbCanBeMade = (src)=> {
    let buckets = ['dmg-gallery-images-dev'];
    if ( src ) {
        for (let i=0; i < buckets.length; i++) {
            if (src.indexOf(buckets[i]) != -1) return true;
        }
    }
    return false;
};

const updateThumbQueueStatus = (src, status, updatedData, errorMessage)=>{
    console.log(`set thumb queue status to [${status}] for [${src}]`);
    for (let i in thumbQueue) {
        if (thumbQueue[i].photo.src == src) {
            thumbQueue[i].status = status;
            if (status == 'error') {
                if (errorMessage) thumbQueue[i].errorMessage = errorMessage;
            }
            if (updatedData) {
                if (updatedData.thumb) thumbQueue[i].photo.thumb = updatedData.thumb;
                if (updatedData.height) thumbQueue[i].photo.height = updatedData.height;
                if (updatedData.width) thumbQueue[i].photo.width = updatedData.width;
            }
        }
    }
    thumbLog();
};

const getThumbQueueStatus = (src)=> {
    for (let i in thumbQueue) {
        if (thumbQueue[i].photo.src == src) {
            return thumbQueue[i];
        }
    }
    return false;
}

const hasThumb = (src)=>{
    for (let i in thumbQueue) {
        if (thumbQueue[i].photo.src == src) {
            if (thumbQueue[i].photo.thumb && thumbQueue[i].photo.thumb!='') {
                return thumbQueue[i].photo.thumb;
            }
            return false;
        }
    }
    return false;
}

const processThumbQueue = ()=>{
    let checkBackLater = false;
    let thumb = '';
    for (let i in thumbQueue) {
        thumbQueue[i].checks++;
        switch (thumbQueue[i].status) {
            case 'pending':
                updateThumbQueueStatus(thumbQueue[i].photo.src, 'progress');
                makeThumb(thumbQueue[i].photo, (e, d) => {
                    console.log('makeThumb response', e, d);
                    if (e) {
                        console.log(e);
                        // t.make_thumb_error = true;
                        return updateThumbQueueStatus(d.photo.src,'error',null,'Error making thumb (1)');
                    }
                    if (d.error) {
                        console.log(d.error, d);
                        // t.make_thumb_error = true;
                        return updateThumbQueueStatus(d.photo.src, 'error', null, d.error);
                    }
                    if (hasThumb(d.photo.src)) {
                        console.log('thumb found before makeThumb callback completed');
                        return; // found it
                    }
                    if (!d.thumb) {
                        return updateThumbQueueStatus(d.photo.src,'error',null,'Error making thumb (2)');
                    }
                    updateThumbQueueStatus(d.photo.src, 'complete', { photo: d.photo, thumb: d.thumb, width: d.width, height: d.height });
                });
                checkBackLater = true;
                break;
            case 'progress':
                checkBackLater = true;
                // this check might be ill placed
                // if (thumbQueue[i].photo.thumb && thumbQueue[i].photo.thumb!='') {
                //     updateThumbQueueStatus(thumbQueue[i].photo.src, 'complete', thumbQueue[i].photo);
                //     console.log('thumb progress check found a thumb, so marking complete');
                // }
                // look up photo record for a thumb value
                awsapi.resetCache();
                console.log(`thumb progress checking with key ${thumbQueue[i].photo.pk}#${thumbQueue[i].photo.sk}`);
                if (thumbQueue[i].checks > 5) {
                    updateThumbQueueStatus(thumbQueue[i].photo.src, 'error', null, 'Thumb generation timeout.');
                    // this will be the last api call for this thumb
                }
                awsapi.getDataWithPkSk(thumbQueue[i].photo.pk, thumbQueue[i].photo.sk, (e, d) => {
                    // console.log('is d an array or an object?',d);
                    // if thumb, then pass it up the chain
                    if (d && d.thumb && d.thumb != '') {
                        thumb = d.thumb;
                        const obj = {
                            photo: d, thumb, height: d.height, width: d.width
                        };
                        updateThumbQueueStatus(d.src, 'complete', obj);
                        // this.$emit('thumbadded', obj);
                    }
                    // else checkBackLater = true; // async so this doesnt make sense - if no thumb yet, call this func again
                });
                
                break;
            case 'complete':
                break;
            case 'error':
                break;
            default:
                console.log('unexpected thumb status', thumbQueue[i]);
        }
    }
    if (checkBackLater) {
        if (!thumbQueueRunning) {
            thumbQueueRunning = true;
            setTimeout(() => {
                processThumbQueue();
            }, 3000);
        }
    } else {
        thumbQueueRunning = false;
    }
    thumbLog();
};

const addToThumbQueue = (photo, gallery) => {
    // can only convert local images
    console.log('addToThumbQueue',photo,gallery);
    if (!photo || !gallery) {
        console.log('addToThumbQueue invalid add, missing photo or gallery');
        return;
    }
    if (!photo.src) {
        console.log('addToThumbQueue invalid photo src');
        return;
    }
    if (thumbCanBeMade(photo.src) && !thumbQueue[photo.src]) {
        thumbQueue[photo.src] = { photo, gallery, status: 'pending', checks: 0 };
        console.log('added to thumb queue',photo);
        processThumbQueue();
    }
};


const awsApi = (method, params, cb) => {
    // const apiName = 'galleryapi';
    let path = '/gallery/thumb';
    // let endpoint = '';
    // let token = '';

    let authData = dbcapi.auth();
    // if (authData.env == 'uat') {
    //     path = '/gallery/thumb';
    //     endpoint = authData.gateway;
    //     token = authData.gatewayToken;
    // }

    // const myInit = { // OPTIONAL
    //     headers: {}, // OPTIONAL
    //     response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
    //     queryStringParameters: {  // OPTIONAL
    //         name: 'param',
    //     },
    // };

    const obj = {
        ...params
    };

    const headers = {
        'x-api-key': authData.gatewayToken,
        'Authorization': 'Bearer ' + authData.token
    };



    const errorHandler = (error) => {
        console.log('thumb.js error', error);
        // always put the src in the response so the queue can update
        if (cb) cb('thumb.js error',{...params, error, photo:{src:params.src}});
    };

    const responseHandler = (response) => {
        // always put the src in the response so the queue can update
        let obj = response ? Object.assign({}, response) : { ...params, photo:{src:params.src}};
        if (!obj.photo) obj.photo = { src: params.src };
        console.log('thumb.js response', response, obj);
        if (cb && params.src.includes(".pdf")) cb(null, obj);
    };

    switch (method.toLowerCase()) {
        case 'put':
        case 'post':
            axios.put(authData.gateway + path, obj, {headers}).then(responseHandler).catch(errorHandler);
            if (cb && !params.src.includes(".pdf")) cb(null, { ...params, photo:{src:params.src}});
            break;
        case 'delete':
            // API.del(apiName, path).then(responseHandler).catch(errorHandler); break;
            cb('invalid request (1)', null); break;
        default:
            cb('invalid request (2)', null);
    }

};

const makeThumb = (ph, cb) => {
    const { pk, sk, src } = ph;
    if (!pk || !sk || !src) {
        return console.log(`could not make thumb with invalid keys or src [${pk}] [${sk}] [${src}]`);
    }
    // awsApi('post', { md: 'makethumb', pk, sk, src, bucket: awsconfig.aws_user_files_s3_bucket, data: awsconfig.aws_dynamodb_table_schemas[0].tableName }, cb);
    awsApi('post', { md: 'makethumb', pk, sk, src }, cb);
};

const makeThumbAsync = async (ph) => {
    const { pk, sk, src } = ph;
    if (!pk || !sk || !src) {
        return console.log(`could not make thumb with invalid keys or src [${pk}] [${sk}] [${src}]`);
    }
    // awsApi('post', { md: 'makethumb', pk, sk, src, bucket: awsconfig.aws_user_files_s3_bucket, data: awsconfig.aws_dynamodb_table_schemas[0].tableName }, cb);
    return new Promise((resolve, reject) => {
        awsApi('post', { md: 'makethumb', pk, sk, src }, (e, data) => {
            if (e) reject(data);
            resolve(data);
        });
    })

};
// const updateSize = (ph, cb) => {
//     const { pk, sk, src } = ph;
//     awsApi('post', { md: 'updatesize', pk, sk, src, bucket: awsconfig.aws_user_files_s3_bucket, data: awsconfig.aws_dynamodb_table_schemas[0].tableName }, cb);
// };

export default {
    addToThumbQueue,
    getThumbQueueStatus,
    thumbCanBeMade,
    debug,
    thumbQueueRunning,
    makeThumbAsync,
}

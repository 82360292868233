// initial state
const state = () => ({
  selectedImages: [],
  setSelectedId: null,
});


// getters
const getters = {
  getSelectedImages(state) {
    const { selectedImages = [] } = state;
    return selectedImages;
  },
}

// actions
const actions = {
  async toggleImage({ commit, state }, image) {
    const isExists = !!state.selectedImages.find(_ => _.sk === image.sk);
    if (!isExists) {
      commit('addImage', image);

    } else {
      commit('removeImage', image);
    }
  },
  cleanSelections({ commit }) {
    commit('setSelected', []);
  },

};

// mutations
const mutations = {
  setSelected(state, newList) {
    state.selectedImages = newList;
    if (state.setSelectedId) state.setSelectedId = null;
  },
  addImage(state, image) {
    state.selectedImages = [image];
    if (state.setSelectedId) state.setSelectedId = null;
  },
  removeImage(state, image) {
    state.selectedImages.splice(state.selectedImages.findIndex(_ => _.sk === image.sk), 1);
  },
  setSelectedId(state, newId) {
    state.setSelectedId = newId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
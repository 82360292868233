import BaseService from './BaseService';
import dbcapi from '@/lib/dbcapi';
import { GatewayType, getApiClientBySource } from '@/services/ApiClient';

class AgentService extends BaseService {
  async loadUserData() {
    try {
      const userData = await dbcapi.getAuthUser();

      console.debug('loaded user', userData);
      return userData;
    } catch (e) {
      console.error('getAuthUser error', e);
    }
    return null;
  }

  async getAgentIdByEmail(email) {
    try {
      const apiClient = getApiClientBySource(GatewayType.MLS);
      const {
        data
      } = await apiClient.post('/getAgentByEmail', { email });

      const { agent_id: agentId } = data;
      return agentId;
    } catch (e) {
      return null;
    }
  }

  async loadPermissions() {
    try {
      const permissionsData = await dbcapi.permissions();

      console.log('api permissions', permissionsData);

      return permissionsData;
    } catch (e) {
      console.log('permissions error', e);
      return null;
    }
  }
}

export default new AgentService();

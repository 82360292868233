import BaseService from "./BaseService";
import awsapi from "../lib/dynamodb";
import s3 from "../lib/s3";
import s3thumb from "@/lib/thumb";
import MLService from "./MLService";

export const GALLERY_TYPE = {
  AG: "AG",
  OF: "OF",
  CO: "CO",
  ML: "ML"
};

export const GALLERY_TYPE_NAME = {
  [GALLERY_TYPE.AG]: "agent",
  [GALLERY_TYPE.OF]: "office",
  [GALLERY_TYPE.CO]: "company",
  [GALLERY_TYPE.ML]: "agent"
};

export const defaultNewGalleryObj = {
  id: "",
  gallery_type: GALLERY_TYPE.AG,
  gallery_name: "",
  gallery_prop_address: "",
  gallery_prop_city: "",
  gallery_prop_state: ""
};

export const GALLERY_ITEM_TYPE = {
  IMAGE: "image",
  PDF: "pdf"
};

class GalleryService extends BaseService {
  async loadGalleryByType(type, userId, externalId) {
    let lang = "";
    try {
      let data = [];
      if (type === GALLERY_TYPE.ML)
        if(externalId) {
          data = await MLService.loadGalleries(externalId);
        } else {
          //
      } else if (userId) {
        data = await awsapi.getDataWithPkPromisified(
          GALLERY_TYPE_NAME[type] + "-" + userId
        ); // => {
      }

      if (!data) return;

      let { data: d = [] } = data;

      let gt = GALLERY_TYPE.AG;

      let obj = {};

      const galleries = [];

      for (let i = 0; i < d.length; i++) {
        // console.log(`${d[i].id}#${d[i].sort} [${i}] ${d.length}`);
        // there could be MLs in the AG response

        if (d[i].sort == "permissions") {
          if (d[i].agent_owner && d[i].agent_owner == "yes")
            this.permissions.agent = true;
          if (d[i].mls_owner && d[i].mls_owner == "yes")
            this.permissions.mls = true;
          if (d[i].office_owner && d[i].office_owner == "yes")
            this.permissions.office = true;
          if (d[i].company_owner && d[i].company_owner == "yes")
            this.permissions.company = true;
          console.log("set perm", d[i]);
          continue;
        }
        if (d[i].sort == "language") {
          if (d[i].name && d[i].name != "") {
            console.log("lang ok with", d[i].name);
            // this.setLang(d[i].name);
            lang = d[i].name;
          } else {
            console.log("lang issue", d[i].name);
          }
          console.log("set lang", d[i]);
          continue;
        }
        obj = {
          id: d[i].gallery_id,
          gallery_id: d[i].id,
          gallery_name: d[i].name,
          gallery_type: d[i].gallery_type,
          photos: d[i].photos || null, // photos get loaded later when the gallery is clicked
          pk: d[i].id,
          sk: d[i].sort,
          created_at: d[i].created_at,
          thumb: d[i].thumb
        };
        gt = GALLERY_TYPE[type];
        /*
        // disabled for now
        if (d[i].gallery_type) gt = d[i].gallery_type;
        if (gt == GALLERY_TYPE.ML || d[i].prop_address) {
          obj.gallery_prop_address = d[i].prop_address;
          obj.gallery_prop_city = d[i].prop_city;
          obj.gallery_prop_state = d[i].prop_state;
          obj.mls_id = d[i].mls_id;
          obj.mls_source = d[i].mls_source;
        }

         */

        if (type === GALLERY_TYPE.ML && gt !== GALLERY_TYPE.ML) {
          continue;
        }

        if (obj.gallery_type !== type) {
          continue;
        }

        galleries.push(obj);
      }

      galleries.sort((a, b) => +b.created_at - +a.created_at);

      return { galleries, lang };
    } catch (e) {
      console.error("Cant load gallery", e);
      throw e;
    }
  }

  async loadGallery(galleryType, id) {
    const { data } = await awsapi.getDataWithPkPromisified(
      galleryType + "-" + id
    );

    const gallery = {}; // first el contains gallery data

    if (!data) return;

    const photos = [];

    const d = data;

    for (let j = 0; j < d.length; j++) {
      if (d[j].sort.indexOf("photo-") == 0) {
        photos.push({
          src: d[j].src,
          created_at: d[j].created_at,
          source_system: d[j].source_system,
          sk: d[j].sort,
          pk: d[j].id,
          width: d[j].width ? d[j].width : 1,
          thumb: d[j].thumb ? d[j].thumb : "",
          height: d[j].height ? d[j].height : 1
        });
      } else if (d[j].sort == "info") {
        if (d[j].gallery_type == GALLERY_TYPE.ML) {
          gallery.gallery_prop_address = d[j].prop_address;
          gallery.gallery_prop_city = d[j].prop_city;
          gallery.gallery_prop_state = d[j].prop_state;
          gallery.mls_id = d[j].mls_id;
          gallery.mls_source = d[j].mls_source;
        }
      }
    }
    gallery.photos = photos;

    return gallery;
  }

  async createGallery(type, galdyn) {
    try {
      await awsapi.insertDataPromisified(galdyn.info);
      await awsapi.insertData(galdyn.gal);
    } catch (e) {
      console.error("gallery create error", e);
    }
  }

  async updateGallery(galdyn) {
    try {
      await awsapi.updateData(galdyn.info);
      await awsapi.updateData(galdyn.gal);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  async deleteGalleryImage(dynph) {
    await awsapi.deleteDataPromisified(dynph.id, dynph.sort);
    const q = [];
    if (dynph.src) {
      q.push(s3.removeFilePromisified(dynph.src));
    }

    if (q.length) {
      await Promise.allSettled(q);
    }
  }

  async deleteGallery(dyngal) {
    const photosSrc = dyngal.photos.map(_ => _.src);
    if (photosSrc && photosSrc.length) {
      await s3.removeFilePromisified(photosSrc);
    }
    await awsapi.deleteDataPromisified(dyngal.gal.id, dyngal.gal.sort);
    await awsapi.deleteDataPromisified(dyngal.info.id, dyngal.info.sort);
  }

  async makeThumb(ph, activeGallery) {
    return s3thumb.makeThumbAsync(ph, activeGallery);
  }
}

export default new GalleryService();

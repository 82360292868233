const productPhotos = () => {
    const phs = [
        {
            id: 1,
            photovar: 'photo1',
            src: '',
            thumb: '',
            page: 1,
            height: 1,
            width: 1,
            active: false,
            name: '' // will be used to stash source photo ids
        },
        {
            id: 2,
            photovar: 'photo2',
            src: '',
            thumb: '',
            page: 2,
            height: 1,
            width: 1,
            active: false,
            name: ''
        },
        {
            id: 3,
            photovar: 'photo3',
            src: '',
            thumb: '',
            page: 2,
            height: 1,
            width: 1,
            active: false,
            name: ''
        }
    ];
    return phs;
}

const orderId = () => {
    return '00987654';
}
const product = () => {
    return {pid:'123'};
}
const nextPage = (orderid) => {
    return 'https://re.deluxe.com/?order=' + orderid;
}

export default {
    productPhotos,
    orderId,
    product,
    nextPage
}

<template>
  <div class="main-flex-container">
    <app-image-card
      :image="imagesrc"
      image-alt="Image"
      v-on:imageClicked="handleImageClick(image.src, $event)"
    />
    <div class="button-container-left">
      <b-button
        v-if="!isSelectable && !isOnlySelect"
        variant="secondary"
        @click="onImageOpen(image, image.src)"
        size="sm"
        class="rounded-0"
      >
        <app-icon icon="zoom-in" />
      </b-button>
    </div>
    <div class="button-container-right">
      <b-button
        v-if="isEditable && !isOnlySelect"
        variant="primary"
        v-on:click.prevent="onImageDelete(image, $event)"
        size="sm"
        class="rounded-0"
      >
        <app-icon icon="delete" />
      </b-button>
    </div>
    <div
        v-if="isSelected"
        class="flex-center"
        @click="unselectImage()"
      >
        <p class="font-weight-bold text-center">
         <app-icon icon="check-circle" />
        </p>
      </div>
  </div>
</template>

<script>
import { GALLERY_ITEM_TYPE, GALLERY_TYPE } from "@/services/GalleryService";
import { mapState } from "vuex";
import AppIcon from '@/components/AppIcon';
import AppImageCard from "../AppImageCard.vue";

export default {
  components: { AppImageCard, AppIcon },
  name: "ImageCard",
  data() {
    return {};
  },
  props: {
    image: Object,
    onImageDelete: Function,
    onImageOpen: Function,
    isEditable: Boolean,
    isSelectable: Boolean,
    selectedIds: Array,
    type: String,
    isOnlySelect: {
      type: Boolean,
      default: false,
    },
  },
  defThumbUrl: process.env.VUE_APP_PDF_THUMB_URL,
  computed: {
    ...mapState({
      selectedImages: (state) => state.ImageSelection.selectedImages,
    }),
    isSelected() {
      const { sk = 0 } = this.image;
      return !!this.selectedImages.find((_) => _.sk === sk);
    },
    description() {
      if (this.image.src.indexOf(".pdf") != -1) return "PDF";
      if (this.image.src.indexOf(".eps") != -1) return "EPS";
      if (this.image.width == 1 || this.image.height == 1) return "";
      return this.image.width + " x " + this.image.height;
    },
    imagesrc() {
      if (this.image.thumb && this.image.thumb != "") return this.image.thumb;
      if (
        this.image.src.toLowerCase().indexOf(".eps") != -1 ||
        this.image.src.toLowerCase().indexOf(".pdf") != -1
      )
        return this.$options.defThumbUrl;
      return this.image.src;
    },
  },
  galleryItemType: GALLERY_ITEM_TYPE,
  galleryType: GALLERY_TYPE,
  methods: {
    unselectImage() {
      this.$store.dispatch("ImageSelection/toggleImage", this.image);
    },
    handleImageClick(src, e) {
      if (e.target.tagName === "IMG" && src) {
        if (this.isOnlySelect) {
          this.$store.dispatch("ImageSelection/toggleImage", this.image);
          return;
        }

        if (!this.isSelectable) {
          window.open(src, "_blank");
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.main-flex-container {
  position: relative;
  min-width: 250px;
  min-height: 250px;
}
.button-container-left {
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  button {
    position: absolute; 
    top: 0px; 
    left: 0px;
    z-index: 100; 
    width:35px; 
    height:35px; 
    display: flex; 
    align-items: center; 
    justify-content: center;
  }
}
.button-container-right {
  position: absolute;
  top: 0;
  right: 0;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  button {
    position: absolute; 
    top: 0px; 
    right: 0px;
    z-index: 100; 
    width:35px; 
    height:35px; 
    display: flex; 
    align-items: center;
  }
}
.flex-center {
  background: black;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: auto;
  position: absolute; 
  left: 0; 
  top: 0; 
  height: 100%; 
  width: 100%;
  background-color: rgba(248, 249, 250, 0.8); 
  z-index: 105;

  & > p {
    color: #2c3e50;
  }
}
</style>

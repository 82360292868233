// initial state
import GalleryService, {
  GALLERY_TYPE,
  defaultNewGalleryObj,
} from '@/services/GalleryService';
import { showErrorMessage, showSuccessMessage } from '@/utils/Notification';
import util from '@/lib/util';
import { i18n } from '@/lib/i8n';

const state = () => ({
  list: null,
  activeList: null,
  officeGalleries: null,
  companyGalleries: null,
  myMLSGalleries: null,

  activeGallery: null,
  activeType: GALLERY_TYPE.AG,
  activeGalleryId: null,

  loading: false,
  mlLoading: false,
  saveLoading: false,
});

// getters
const getters = {
  getFirstActivePhotoThumb(state) {
    const thumbs = state.activeGallery.photos
      .map(_ => _.thumb)
      .filter(_ => _.length);
    if (thumbs.length) {
      const [firstThumb] = thumbs;
      return firstThumb;
    }
    return null;
  },
  getAllActiveThumbs(state) {
    return state.activeGallery.photos.map(_ => _.thumb).filter(_ => _.length);
  },
  getActiveGalleryAndType(state) {
    return { galleryId: state.activeGallery.id, type: state.activeType };
  },
};

// actions
const actions = {
  async createThumb({ state }, ph) {
    const { activeGallery } = state;

    // console.log('GA makeThumb', ph, activeGallery);
    // s3thumb.addToThumbQueue(ph, activeGallery);
    return await GalleryService.makeThumb(ph, activeGallery);
  },

  async deleteGallery({ commit, state, rootGetters }, { galleryId, type }) {
    commit('setSaveLoading', true);
    try {
      const user = rootGetters['Auth/getAgent'];
      const permissions = rootGetters['Auth/getPermissions'];

      if (permissions[state.activeGallery.gallery_type]) {
        const info = {
          agent: user.agent,
          office: user.office,
          company: user.company,
        };
        const dyngal = util.gallery(state.activeGallery, info);
        await GalleryService.deleteGallery(dyngal);

        commit('removeGallery', { galleryId, type });
        showSuccessMessage('Gallery was successfully Deleted');
      } else {
        throw new Error('Permission Error');
      }
    } catch (e) {
      // TODO: remove after error impl
      showErrorMessage(e.message);
      console.error(e);
    } finally {
      commit('setSaveLoading', false);
    }
  },

  async deleteGalleryImage(
    { commit, rootGetters, getters, dispatch },
    { image, galleryType, galleryId, gallery },
  ) {
    commit('setSaveLoading', true);

    try {
      const user = rootGetters['Auth/getAgent'];

      const info = {
        agent: user.agent,
        office: user.office,
        company: user.company,
      };

      const dynph = util.photo(image, info);

      await GalleryService.deleteGalleryImage(dynph);

      if (gallery && gallery.thumb && gallery.thumb === image.thumb) {
        let allThumbs = getters['getAllActiveThumbs'];
        allThumbs = allThumbs.filter(_ => _ !== image.thumb);
        const [newThumb = ''] = allThumbs;
        await dispatch('updateActiveGalleryThumb', newThumb);
      }

      commit('removeImage', { image, galleryId, type: galleryType });

      showSuccessMessage(i18n.t('Image was successfully Deleted'));

      return true;
    } catch (e) {
      // TODO: remove after error impl
      showErrorMessage('Cannot delete gallery image');
      console.error(e);
    } finally {
      commit('setSaveLoading', false);
    }
  },

  async updateActiveGalleryThumb({ dispatch, state }, newThumb) {
    const updatedGallery = { ...state.activeGallery };
    updatedGallery.thumb = newThumb;

    return dispatch('updateGalleryName', updatedGallery);
  },

  async updateGalleryName({ commit, rootGetters }, newGallery) {
    commit('setSaveLoading', true);
    try {
      newGallery = { ...defaultNewGalleryObj, ...newGallery };
      newGallery.updated_at = util.dateStamp();
      newGallery.gallery_name = util.galleryName(newGallery);

      if (newGallery.gallery_name == '') {
        return;
      }
      const user = rootGetters['Auth/getAgent'];

      const info = {
        agent: user.agent,
        office: user.office,
        company: user.company,
      };
      const dynGallery = util.gallery(newGallery, info);

      try {
        await GalleryService.updateGallery(dynGallery);
      } catch (e) {
        showErrorMessage('Gallery Update failed');
        throw e;
      }

      commit('updateGallery', {
        type: newGallery.gallery_type,
        gallery: newGallery,
      });
    } catch (e) {
      // TODO: remove after error impl
      console.error(e);
      throw e;
    } finally {
      commit('setSaveLoading', false);
    }
  },

  async createGallery({ commit, state, rootGetters }, newGallery) {
    commit('setSaveLoading', true);
    try {
      newGallery = {
        ...defaultNewGalleryObj,
        ...newGallery,
        id: util.guid('gallery'),
      };
      newGallery.created_at = util.dateStamp();
      newGallery.gallery_name = util.galleryName(newGallery);

      if (newGallery.gallery_name == '') {
        return;
      }
      const user = rootGetters['Auth/getAgent'];

      const info = {
        agent: user.agent,
        office: user.office,
        company: user.company,
      };
      const dynGallery = util.gallery(newGallery, info);
      await GalleryService.createGallery(newGallery.gallery_type, dynGallery);

      //------------------------------------------------------

      const updatedGalleries = [
        { ...newGallery, ...dynGallery },
        ...state.activeList,
      ];
      commit('setActiveList', updatedGalleries);

      switch (newGallery.gallery_type) {
        case GALLERY_TYPE.AG:
          commit('setMyList', updatedGalleries);

          break;
        case GALLERY_TYPE.CO:
          commit('setCompanyList', updatedGalleries);
          break;
        case GALLERY_TYPE.ML:
          commit('setMyMLSList', updatedGalleries);
          break;
        case GALLERY_TYPE.OF:
          commit('setOfficeList', updatedGalleries);
          break;
        default:
          break;
      }
      // commit('setMyList', updatedGallery);
    } catch (e) {
      // TODO: remove after error impl
      console.error(e);
      throw e;
    } finally {
      commit('setSaveLoading', false);
    }
  },

  async loadGalleriesByType(
    { commit, state, rootGetters, dispatch },
    { type, isReload = false },
  ) {
    //  commit("setActiveType", type);

    const user = rootGetters['Auth/getAgent'];

    switch (type) {
      case GALLERY_TYPE.AG:
        if (!state.list || isReload) {
          !isReload && commit('setLoading', true);
          commit('setActiveList', []);
          try {
            const {
              galleries: list,
              lang,
            } = await GalleryService.loadGalleryByType(type, user.agent);
            if (lang) {
              dispatch('Main/changeLang', lang, { root: true });
            }
            commit('setActiveList', list);
            commit('setMyList', list);
          } catch (e) {
            showErrorMessage('Cannot load my galleries');
          } finally {
            commit('setLoading', false);
          }
        } else {
          commit('setActiveList', state.list);
        }

        break;
      case GALLERY_TYPE.CO:
        if (!state.companyGalleries || isReload) {
          commit('setLoading', true);
          commit('setActiveList', []);
          try {
            const {
              galleries: list,
              lang,
            } = await GalleryService.loadGalleryByType(type, user.company);
            commit('setActiveList', list);
            commit('setCompanyList', list);

            if (lang) {
              dispatch('Main/changeLang', lang, { root: true });
            }
          } catch (e) {
            showErrorMessage('Cannot Load company gallery');
          } finally {
            commit('setLoading', false);
          }
        } else {
          commit('setActiveList', state.companyGalleries);
        }
        break;
      case GALLERY_TYPE.ML:
        if (!state.myMLSGalleries || isReload) {
          const externalId = rootGetters['Agent/getAgentExternalId'];
          commit('setMlLoading', true);

          if (!externalId){
            console.error('No external id. todo: fix it');
            return;
          }
          try {
            commit('setMyMLSList', []);
            commit('setActiveList', []);
            const {
              galleries: list,
              lang,
            } = await GalleryService.loadGalleryByType(
              type,
              user ? user.agent : null,
              externalId,
            );
            commit('setActiveList', list);
            commit('setMyMLSList', list);
            if (lang) {
              dispatch('Main/changeLang', lang, { root: true });
            }
          } catch (e) {
            console.error(e);
            showErrorMessage('Cannot load MLS Gallery');
            commit('setMyMLSList', []);
          } finally {
            commit('setMlLoading', false);
          }
        } else {
          commit('setActiveList', state.myMLSGalleries);
        }
        break;
      case GALLERY_TYPE.OF:
        if (!state.officeGalleries || isReload) {
          commit('setLoading', true);
          commit('setActiveList', []);
          try {
            const {
              galleries: list,
              lang,
            } = await GalleryService.loadGalleryByType(type, user.office);
            commit('setActiveList', list);
            commit('setOfficeList', list);

            if (lang) {
              dispatch('Main/changeLang', lang, { root: true });
            }
          } catch (e) {
            showErrorMessage('Cannot load office galleries');
          } finally {
            commit('setLoading', false);
          }
        } else {
          commit('setActiveList', state.officeGalleries);
        }
        break;
      default:
        break;
    }
    if (state.activeGalleryId) {
      const actGallery = state.activeList.find(_ => _.id === state.activeGalleryId);
      if (actGallery) {
        commit('setActiveGallery', actGallery);
      }
      // commit('loadAndSetActiveGallById', )
    }
  },

  async loadGallery(
    { commit, state, dispatch },
    { type, id, selectedPhotoId },
  ) {

    commit('setActiveGallery', null);

    commit('setLoading', true);
    await dispatch('loadGalleriesByType', { type, isSuppressLoader: true });
    const galleriesList = state.activeList;

    try {
      if (Array.isArray(galleriesList)) {
        const g = galleriesList.find(_ => _.id === id);
        if (g) {
          let galleryDetails = g;
          if (g.gallery_type !== GALLERY_TYPE.ML) {

            galleryDetails = await GalleryService.loadGallery(type, id);
          }

          if (g) {
            // g.photos = galleryDetails.photos;
            commit('setActiveGallery', { ...g, ...galleryDetails });
            // commit('updateGallery', { type, gallery: { ...g, ...galleryDetails } });
            commit('updateGallery', {
              type,
              gallery: { ...g, ...galleryDetails },
            });
            if (selectedPhotoId) {
              const selectedImage = galleryDetails.photos.find(
                i => i.pk === selectedPhotoId,
              );
              dispatch('ImageSelection/toggleImage', selectedImage, {
                root: true,
              });
            }
          }
        }
      }
    } catch (e) {
      //
    } finally {
      commit('setLoading', false);
    }
    // await dispatch('loadGalleriesByType', { type });

    // commit('setActiveGallery', list);
  },
};

// mutations
const mutations = {
  setMyList(state, newList) {
    state.list = newList;
  },
  setOfficeList(state, newList) {
    state.officeGalleries = newList;
  },
  setCompanyList(state, newList) {
    state.companyGalleries = newList;
  },
  setMyMLSList(state, newList) {
    state.myMLSGalleries = newList;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setMlLoading(state, status) {
    state.mlLoading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },
  setActiveList(state, list) {
    state.activeList = list;
  },
  setActiveGallery(state, gallery) {
    state.activeGallery = gallery ? { ...gallery } : gallery;
    if (gallery) {
      state.activeGalleryId = null;
    }
  },
  setActiveGalleryId(state, gId) {
    state.activeGalleryId = gId;
  },
  setActiveType(state, type) {
    state.activeType = type;
  },
  updateGallery(state, { type, gallery }) {
    switch (type) {
      case GALLERY_TYPE.ML:
        state.myMLSGalleries = state.myMLSGalleries.map(_ =>
                                                          _.id === gallery.id ? gallery : _,
        );
        break;
      case GALLERY_TYPE.AG:
        state.list = state.list.map(_ => (_.id === gallery.id ? gallery : _));
        break;
      case GALLERY_TYPE.CO:
        state.companyGalleries = state.companyGalleries.map(_ =>
                                                              _.id === gallery.id ? gallery : _,
        );
        break;
      case GALLERY_TYPE.OF:
        state.officeGalleries = state.officeGalleries.map(_ =>
                                                            _.id === gallery.id ? gallery : _,
        );
        break;
      default:
        break;
    }
    if (state.activeGallery.id === gallery.id) {
      state.activeGallery = gallery;
    }
  },
  removeImage(state, { image, galleryId, type }) {
    let gallery;

    switch (type) {
      case GALLERY_TYPE.ML:
        gallery = state.myMLSGalleries.find(_ => _.id === galleryId);
        break;
      case GALLERY_TYPE.AG:
        gallery = state.list.find(_ => _.id === galleryId);
        break;
      case GALLERY_TYPE.CO:
        gallery = state.companyGalleries.find(_ => _.id === galleryId);
        break;
      case GALLERY_TYPE.OF:
        gallery = state.officeGalleries.find(_ => _.id === galleryId);
        break;
      default:
        break;
    }

    if (gallery && gallery.photos) {
      gallery.photos = gallery.photos.filter(
        _ => _.sk !== image.sk && _.src !== image.src,
      );
      if (state.activeGallery && state.activeGallery.id === galleryId) {
        state.activeGallery = gallery;
      }
    } else if (state.activeGallery && state.activeGallery.photos){

      state.activeGallery.photos = state.activeGallery.photos.filter(
        _ => _.sk !== image.sk && _.src !== image.src,
      );
    }
  },
  removeGallery(state, { galleryId, type }) {
    switch (type) {
      case GALLERY_TYPE.ML:
        state.myMLSGalleries = state.myMLSGalleries.filter(
          _ => _.id !== galleryId,
        );
        break;
      case GALLERY_TYPE.AG:
        state.list = state.list.filter(_ => _.id !== galleryId);
        break;
      case GALLERY_TYPE.CO:
        state.companyGalleries = state.companyGalleries.filter(
          _ => _.id !== galleryId,
        );
        break;
      case GALLERY_TYPE.OF:
        state.officeGalleries = state.officeGalleries.filter(
          _ => _.id !== galleryId,
        );
        break;
      default:
        break;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

<template>
  <b-container>
    <b-row class="pt-4">
      <b-col class="single-gallery-cont p-0 mt-4">
        <new-card v-if="isEditable && !isOnlySelect" :on-click="handleCreateNewClick" :title="$t('Add Image')" />

        <div v-for="el in listPage" :key="el.id" class="card-wrap">
          <image-card :image="el" :is-editable="isEditable" :type="galleryType" :is-only-select="isOnlySelect" :on-image-delete="handleDeleteImage" :on-image-open="handleOpenImage" />
        </div>

        <upload-image-modal :show="isUploadWnd" @onClose="isUploadWnd=false" />
      </b-col>
    </b-row>

    <delete-modal
        :show="isDeleteShow"
        :delete-id="deleteId"
        :title="$t('Delete Photo?')"
        @on-close="handleCloseDeleteWnd"
        @on-ok="handleDeleteImageConfirmed"
        id="deleteWnd"
    >
      <template #default>
        <p class="text-left">{{$t('Are you sure you want to permanently delete this photo?')}}
        </p>
      </template>
    </delete-modal>

    <div v-if="totalCnt > itemsPerPage" class="navigation-container">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalCnt"
        :per-page="itemsPerPage"
        aria-controls="my-table"
      ></b-pagination>
    </div>


  </b-container>
</template>

<script>
import { GALLERY_ITEM_TYPE } from '@/services/GalleryService';
import NewCard from '@/components/GalleryCards/NewCard';
import ImageCard from '@/components/GalleryCards/ImageCard';
import UploadImageModal from '@/components/UploadImageModal';
import DeleteModal from '@/components/DeleteModal';

export default {
  name: 'ImagesListBox',
  data() {
    return {
      isUploadWnd: false,
      isDeleteShow: false,
      deleteId: { },
      currentPage: 1, 
      tuSam: 0
    }
  },
  components: { DeleteModal, UploadImageModal, ImageCard, NewCard },
  props: {
      list: {
      type: Array,
      default: function() {
        return [];
      },
    },
    galleryType: String,
    galleryId: String,
    isEditable: Boolean,
    gallery: Object,
    isOnlySelect: {
      type: Boolean,
      default: false,
    }
  },

  computed: {
    itemsPerPage() {
      // return this.isAdmin ? ((+process.env.VUE_APP_PAGE_LIMIT) - 1) : +process.env.VUE_APP_PAGE_LIMIT;
      
      return +process.env.VUE_APP_GALLERY_LIMIT
       
    },
     totalCnt() {
      return this.list.length;
    },
    listPage() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.list.slice(start, end);
    },

  },
  galleryItemType: GALLERY_ITEM_TYPE,
  methods: {
    handleCloseDeleteWnd() {
      this.isDeleteShow = false;
    },
    handleCreateNewClick() {
      this.$emit('onOpenNew');
    },
    handleOpenImage(id, src) {
      window.open(src,'_blank');
    },
    handleExport(id) {
      alert(`Export handler! ${id}`);
    },
    handleDeleteImageConfirmed(image) {
      this.isDeleteShow = false;
      this.deleteId = {};
      this.$store.dispatch('Galleries/deleteGalleryImage',
                           {image, galleryId: this.galleryId, galleryType: this.galleryType, gallery: this.gallery }, { root: true })
    },
    handleDeleteImage(image, event) {
      event.stopPropagation();
      event.preventDefault();

      this.deleteId = image;
      this.isDeleteShow = true;

    },
  },
}
</script>

<style scoped lang="scss">

.single-gallery-cont {
  padding: 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 24%);
    justify-content: space-between;
    grid-gap: 0;


}

.card-wrap { 
  flex-basis: 30%;
  max-height: 209px;
  border: none;
  margin-bottom: 1.5rem;

  .main-flex-container {
    max-height: 209px;

    .gallery-card {
      max-height: 209px;

     
    }
  }
}

.navigation-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 25px;
  z-index: 999;
}



</style>
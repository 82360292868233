import BaseService from './BaseService';
import { GALLERY_TYPE } from './GalleryService';
import { GatewayType } from '@/services/ApiClient';

const PhotoTypes = {
  FULL: 'Full',
  THUMB: 'Thumb',
};

class MLService extends BaseService {
  constructor() {
    super(GatewayType.MLS);
  }

  async loadGalleries(agentId) {
    const {
      data: { listings: items },
    } = await this.apiClient.post('/getListings', {
      userId: agentId,
    });

    if (items && Array.isArray(items)) {
      const galleries = items
        .filter(_ => _.media)
        .map(list => {
          const { media: { photos = [] } = {} } = list;
          let gPhotos = [];

          photos.forEach(({ sequence: i, size, url }) => {
            if (!(+i in gPhotos)) gPhotos[+i] = {};
            const _photo = gPhotos[+i];
            if (size === PhotoTypes.FULL) _photo.src = url;
            if (size === PhotoTypes.THUMB) _photo.thumb = url;
            _photo.pk = i;
            _photo.sk = _photo.src ? _photo.src.substring(url.lastIndexOf('/') + 1) : process.env.VUE_APP_GALLERY_THUMB_URL;
          });
          const {
            city,
            state,
            street_address,
            zip,
            dbc_listing_id: listingId,
            modify_date: modifyDate,
          } = list;
          gPhotos = gPhotos.filter(_ => _);
          return {
            photos: gPhotos,
            name: `${[street_address, city, state].join(', ')} ${zip}`,
            gallery_type: GALLERY_TYPE.ML,
            gallery_id: listingId,
            id: listingId,
            sort: listingId,
            updated_at: modifyDate,
            thumb: gPhotos[0] ? gPhotos[0].thumb || gPhotos[0].src : '',
          };
        });

      return { data: galleries };
    }

    return { data: [] };
  }
}

export default new MLService();

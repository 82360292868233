import Vue from 'vue';
import Vuex from 'vuex';

import Agent from './modules/agent';
import Auth from './modules/auth';
import Galleries from './modules/galleries';
import Main from './modules/main';
import ImageSelection from './modules/imageSelection';

Vue.config.productionTip = false


Vue.use(Vuex);

const state = {
}

const mutations = {
}

const debug = process.env.NODE_ENV !== 'production';

Vue.config.devtools = true

export default new Vuex.Store({
                                state,
                                mutations,
                                modules: {
                                  Agent,
                                  Auth,
                                  Galleries,
                                  Main,
                                  ImageSelection,
                                },
                                strict: debug,
                                devtools: debug,
                              });
// initial state
import { i18n } from '@/lib/i8n';

const state = () => ({
  lang: 'en',
  isOrderMode: false,
  orderData: {orderId : '', listId: ''},
  isUploadPage: false,
});


// getters
const getters = {
  getLang(state) {
    const { lang } = state;
    return lang;
  },
  trans(state, text) {
    return i18n.t(text, state.lang);
  },
  getOrderData(state){
    return state.orderData;
  },
}

// actions
const actions = {
  async changeLang({ commit }, newLang) {
    commit('setLang', newLang);
    i18n.locale = newLang;
  },
  setOrderMode({ commit, dispatch }, orderData) {
    commit('setIsOrderMode', true);
    commit('setOrderData', orderData);
    dispatch('ImageSelection/cleanSelections', null, { root: true });
  },

};

// mutations
const mutations = {
  setLang(state, newLang) {
    state.lang = newLang;
  },
  setIsOrderMode(state, newStatus) {
    state.isOrderMode = !!newStatus;
  },
  setOrderData(state, orderData) {
    state.orderData = orderData;
  },
  setIsUploadPage(state, stat) {
    state.isUploadPage = stat;
  }


};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
// import { API } from 'aws-amplify';
import dbcapi from './dbcapi';
import axios from 'axios';

let cachedResponses = {}; // dont get wrecked by click happies

const cachedResponseKey = (pk, sk) => {
  const k = pk + (sk ? ('#' + sk) : '');
  return k;
};

const resetCache = () => {
  cachedResponses = {};
}

const awsApi = (pk, sk, method, params, cb) => {
  // const apiName = 'galleryapi';
  let path = '/gallery/photos';
  // let endpoint = '';
  // let token = 'abc';
  const authData = dbcapi.auth();
  // if (authData.env == 'uat') {
  //     // endpoint = 'https://7n7l08yp06.execute-api.us-west-2.amazonaws.com/uat';
  //     path = '/gallery/photos';
  //     token = auth.gatewayToken;
  // }

  if (sk) {
    path += '/object';
    if (pk) {
      path += '/' + pk;
    } else {
      path += '/:';
    }
    path += '/' + sk;
  } else if (pk) {
    path += '/' + pk;
  } else if (method.toLowerCase() == 'get') {
    path += '/:'; // weird
  }
  // const myInit = { // OPTIONAL
  //     headers: {}, // OPTIONAL
  //     response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  //     queryStringParameters: {  // OPTIONAL
  //         name: 'param',
  //     },
  // };

  // 20201001 not sure if this is causing probs so comment out for now
  // if (method.toLowerCase() == 'get' && cachedResponses[cachedResponseKey(pk, sk)])
  //     return cb(null, cachedResponses[cachedResponseKey(pk, sk)]);

  const obj = {
    body: params,
  };

  const headers = {
    'x-api-key': authData.gatewayToken,
    'Authorization': 'Bearer ' + authData.token,
  };

  const errorHandler = (error) => {
    let e = 'dyn api error ' + method + '-' + pk + '-' + sk;
    console.log(e, error);
    if (cb) cb(e);
  };

  const responseHandler = (response) => {
    console.log('dynamo response ' + method + '-' + pk + '-' + sk, response);
    if (method.toLowerCase() == 'get') {
      cachedResponses[cachedResponseKey(pk, sk)] = response;
    } else {
      // cachedResponses[cachedResponseKey(pk, sk)] = false;
      resetCache();
    }
    // console.log('cachedResponses', cachedResponses);
    if (cb) cb(null, response);
  };

  if (method.toLowerCase() == 'delete') {
    // if (authData.env == 'uat') {
    console.log('delete', authData.gateway + path);
    axios.delete(authData.gateway + path, { headers }).then(responseHandler).catch(errorHandler);
    // } else {
    //     API.del(apiName, path).then(responseHandler).catch(errorHandler);
    // }
  } else if (method.toLowerCase() == 'put' || method.toLowerCase() == 'post') {
    // if (authData.env == 'uat') {
    console.log('put', authData.gateway + path, obj.body);
    axios.put(authData.gateway + path, obj.body, { headers }).then(responseHandler).catch(errorHandler);
    // } else {
    //     API.put(apiName, path, obj).then(responseHandler).catch(errorHandler);
    // }
  } else {
    // if (authData.env == 'uat') {
    console.log('get', authData.gateway + path);
    axios.get(authData.gateway + path, { headers }).then(responseHandler).catch(errorHandler);
    // } else {
    // API.get(apiName, path).then(responseHandler).catch(errorHandler);
    // }
  }

};

const awsApiPromisified = async (pk, sk, method, params, pathParam) => {
  // const apiName = 'galleryapi';
  let path = pathParam || '/gallery/photos';
  // let endpoint = '';
  // let token = 'abc';
  const authData = dbcapi.auth();
  // if (authData.env == 'uat') {
  //     // endpoint = 'https://7n7l08yp06.execute-api.us-west-2.amazonaws.com/uat';
  //     path = '/gallery/photos';
  //     token = auth.gatewayToken;
  // }

  if (sk) {
    path += '/object';
    if (pk) {
      path += '/' + pk;
    } else {
      path += '/:';
    }
    path += '/' + sk;
  } else if (pk) {
    path += '/' + pk;
  } else if (method.toLowerCase() == 'get') {
    path += '/:'; // weird
  }
  // const myInit = { // OPTIONAL
  //     headers: {}, // OPTIONAL
  //     response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  //     queryStringParameters: {  // OPTIONAL
  //         name: 'param',
  //     },
  // };

  // 20201001 not sure if this is causing probs so comment out for now
  // if (method.toLowerCase() == 'get' && cachedResponses[cachedResponseKey(pk, sk)])
  //     return cb(null, cachedResponses[cachedResponseKey(pk, sk)]);

  const obj = {
    body: params,
  };

  const headers = {
    'x-api-key': authData.gatewayToken,
    'Authorization': 'Bearer ' + authData.token,
  };

  const errorHandler = (error) => {
    let e = 'dyn api error ' + method + '-' + pk + '-' + sk;
    console.log(e, error);
    // if (cb) cb(e);
    return e;
  };

  const responseHandler = (response) => {
    console.log('dynamo response ' + method + '-' + pk + '-' + sk, response);
    if (method.toLowerCase() == 'get') {
      cachedResponses[cachedResponseKey(pk, sk)] = response;
    } else {
      // cachedResponses[cachedResponseKey(pk, sk)] = false;
      resetCache();
    }
    // console.log('cachedResponses', cachedResponses);
    return response;
    // if (cb) cb(null, response);
  };

  if (method.toLowerCase() == 'delete') {
    // if (authData.env == 'uat') {
    console.log('delete', authData.gateway + path, params);
    try {
      const res = await axios.delete(authData.gateway + path, { headers, data: params }, ); //.then(responseHandler).catch(errorHandler);
      return responseHandler(res);
    } catch (e) {
      errorHandler(e);
      throw e;
    }
    // } else {
    //     API.del(apiName, path).then(responseHandler).catch(errorHandler);
    // }
  } else if (method.toLowerCase() == 'put' || method.toLowerCase() == 'post') {
    // if (authData.env == 'uat') {
    console.log('put', authData.gateway + path, obj.body);
    try {
      const res = await axios.put(authData.gateway + path, obj.body, { headers }); // .then(responseHandler).catch(errorHandler);
      return responseHandler(res);
    } catch (e) {
      errorHandler(e);
      throw e;
    }
    // } else {
    //     API.put(apiName, path, obj).then(responseHandler).catch(errorHandler);
    // }
  } else {
    // if (authData.env == 'uat') {
    console.log('get', authData.gateway + path);
    try {
      const res = await axios.get(authData.gateway + path, { headers }); // .then(responseHandler).catch(errorHandler);
      return responseHandler(res);
    } catch (e) {
      errorHandler(e);
      throw e;
    }

  }

};


export default {
  getDataWithPk: (pk, cb) => {
    awsApi(pk, null, 'get', null, cb);
  },
  getDataWithPkSk: (pk, sk, cb) => {
    awsApi(pk, sk, 'get', null, cb);
  },
  deleteData: (pk, sk, cb) => {
    awsApi(pk, sk, 'delete', null, cb);
  },
  insertData: (params, cb) => {
    awsApi(null, null, 'put', params, cb);
  },
  updateData: (params, cb) => {
    awsApi(null, null, 'put', params, cb);
  },

  getDataWithPkPromisified: async (pk) => awsApiPromisified(pk, null, 'get', null),
  getDataWithPkSkPromisified: async (pk, sk) => awsApiPromisified(pk, sk, 'get', null),
  deleteDataPromisified: async (pk, sk) =>    awsApiPromisified(pk, sk, 'delete', null),
  insertDataPromisified: async (params) =>     awsApiPromisified(null, null, 'put', params),
  updateDataPromisified: async (params) => awsApiPromisified(null, null, 'put', params),
  resetCache,
}

// import { API } from 'aws-amplify';
// import awsconfig from '../aws-exports';
import dbcapi from './dbcapi';
import axios from 'axios';


const awsApi = (method, params, cb) => {
  // const apiName = 'galleryapi';
  let path = '/gallery/remove';

  let authData = dbcapi.auth();

  // const myInit = { // OPTIONAL
  //     headers: {}, // OPTIONAL
  //     response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
  //     queryStringParameters: {  // OPTIONAL
  //         name: 'param',
  //     },
  // };

  const obj = {
    body: params,
  };

  const errorHandler = (error) => {
    console.log('s3.js remove error', error, error.response);
    if (cb) cb(console.response);
  };

  const responseHandler = (response) => {
    console.log('s3.js remove response', response);
    // if (method.toLowerCase() == 'get') {
    //     cachedResponses[cachedResponseKey(pk, sk)] = response;
    // }
    // console.log('cachedResponses', cachedResponses);
    if (cb) cb(null, response);
  };

  const headers = {
    'x-api-key': authData.gatewayToken,
    'Authorization': 'Bearer ' + authData.token,
  };

  if (method.toLowerCase() == 'delete') {
    // if (authData.env == 'uat') {
    axios.delete(authData.gateway + path, obj, { headers }).then(responseHandler).catch(errorHandler);
    // } else {
    //     API.del(apiName, path, obj).then(responseHandler).catch(errorHandler);
    // }
  } else if (method.toLowerCase() == 'post') {
    // if (authData.env == 'uat') {
    axios.post(authData.gateway + path, obj, { headers }).then(responseHandler).catch(errorHandler);
    // } else {
    //     API.post(apiName, path, obj).then(responseHandler).catch(errorHandler);
    // }
    // } else if (method.toLowerCase() == 'put') {
    //     API
    //         .put(apiName, path, obj)
    //         .then(responseHandler)
    //         .catch(errorHandler);
  } else {
    // API
    //     .get(apiName, path)
    //     .then(responseHandler)
    //     .catch(errorHandler);
    console.log('s3.js remove invalid request');
    if (cb) cb('invalid request', null);
  }

};

const awsApiPromisified = async (method, params) => {

  let path = '/gallery/remove';

  let authData = dbcapi.auth();


  const obj = {
    body: params,
  };

  const errorHandler = (error) => {
    console.log('s3.js remove error', error, error.response);
    throw error;
  };

  const responseHandler = (response) => {
    console.log('s3.js remove response', response);

    return response;
  };

  const headers = {
    'x-api-key': authData.gatewayToken,
    'Authorization': 'Bearer ' + authData.token,
  };

  try {
    if (method.toLowerCase() == 'delete') {

      const res = await axios.delete(authData.gateway + path, { headers, data: params });// .then(responseHandler).catch(errorHandler);
      return responseHandler(res);
    } else if (method.toLowerCase() == 'post') {

      const res = await axios.post(authData.gateway + path, obj, { headers }); //.then(responseHandler).catch(errorHandler);
      return responseHandler(res);
    } else {

      console.log('s3.js remove invalid request');
      throw new Error('invalid request');
    }
  } catch (err) {
    errorHandler(err);
  }

};


const removeFile = (url, cb) => {
  awsApi('delete', { url }, cb);
};

const removeFilePromisified = async (url) => {
  await awsApiPromisified('delete', { url });
};

const copyFile = (src, thumb, cb) => {
  // console.log('copyFile', ph1, ph2);
  // if (cb) cb();
  awsApi('post', { src, thumb }, cb);
};

export default {
  copyFile,
  removeFile,
  removeFilePromisified,
}

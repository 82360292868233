<template>
  <div class="gallery-card" @click="(event) => imageClicked(event)">

    <div>
      <transition name="fade">
        <div>
          <slot></slot>
        </div>
      </transition>
    </div>
    <app-loader-centered v-show="!imageLoaded && !hideLoader" />
    <transition name="fade">
      <img
        :src="imageError ? defaultImage : image"
        @load="onImgLoad"
        v-show="imageLoaded"
        @error="onImgError"
        :alt="imageAlt"
      />
    </transition>
  </div>
</template>

<script>
import AppLoaderCentered from '@/components/AppLoaderCentered'
export default {
  name: "AppImageCard",
  data() {
    return {
      imageLoaded: false,
      defaultImage: process.env.VUE_APP_GALLERY_THUMB_URL,
      imageError: false,
    };
  },
  props: {
    image: {
      type: String,
      required: true,
    },
    imageAlt: String,
    hideLoader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AppLoaderCentered,
  },
  computed: {},
  methods: {
    onImgLoad() {
      this.imageLoaded = true;
    },

    imageClicked(event) {
      this.$emit("imageClicked", event);
    },
    onImgError() {
      this.imageError = true;
    },
  },
};
</script>

<style scoped lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.gallery-card {
  position: relative;
  flex-basis: 30%;
  min-height: 250px;
  border: none;
  max-width: 550px;

  img  {
    position: relative;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    height: 209px;
  }

}

</style>

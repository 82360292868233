<template>
  <div>
    <b-row class="m-0 p-0 main">
      <main role="main" class="col-md-12 ml-sm-auto text-left col-lg-12 px-md-4">
        <b-container fluid class="p-3 pl-0">
          <MainGalleriesPage v-if="!activeGallery && !isUploadPage" />
        </b-container>
        <b-container>
          <SingleGalleryPage v-if="activeGallery && !isUploadPage" />
          <UploadImagesPage v-if="isUploadPage" />

        </b-container> <!-- /container -->
      </main>

    </b-row>

  </div>

</template>

<script>

import MainGalleriesPage from '../views/MainGalleriesPage';
import SingleGalleryPage from '../views/SingleGalleryPage';
import UploadImagesPage from '../views/UploadImagesPage';
import { mapState } from 'vuex';

export default {
  name: 'MainContainer',
  components: { MainGalleriesPage, SingleGalleryPage, UploadImagesPage },
  computed: {
    ...mapState({
                  activeGallery: state => state.Galleries.activeGallery,
                  isUploadPage: state => state.Main.isUploadPage,
                }),

  },
}
</script>

<style scoped>
.main {
  min-height: 74vh;
}
</style>
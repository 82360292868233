<template>
  <b-input-group class="m-0 float-right search-input-group" size="lg">
  
    <b-form-input size="lg" v-model="search" class="search-input" :placeholder="`${$t('Search')}...`" debounce="500"></b-form-input>
    <b-input-group-append  size="lg">
      <b-button size="lg" v-if="search" style="height: 38px" class="search-button" variant="secondary" @click="search = ''">x</b-button>
      <b-button size="lg" style="height: 38px" class="search-button h1 font-weight-bold search-icon">
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M9.17746 9.54464C9.96317 8.75893 10.356 7.81548 10.356 6.71429C10.356 5.61309 9.96317 4.67262 9.17746 3.89286C8.39769 3.10714 7.45722 2.71428 6.35603 2.71428C5.25484 2.71428 4.31138 3.10714 3.52567 3.89286C2.74591 4.67262 2.35603 5.61309 2.35603 6.71429C2.35603 7.81548 2.74591 8.75893 3.52567 9.54464C4.31138 10.3244 5.25484 10.7143 6.35603 10.7143C7.45722 10.7143 8.39769 10.3244 9.17746 9.54464ZM14.9275 14.1429C14.9275 14.4524 14.8144 14.7202 14.5882 14.9464C14.362 15.1726 14.0941 15.2857 13.7846 15.2857C13.4632 15.2857 13.1953 15.1726 12.981 14.9464L9.91853 11.8929C8.85305 12.631 7.66555 13 6.35603 13C5.50484 13 4.68936 12.8363 3.9096 12.5089C3.13579 12.1756 2.46615 11.7292 1.90067 11.1696C1.34115 10.6042 0.894717 9.93452 0.561384 9.16071C0.234003 8.38095 0.0703125 7.56548 0.0703125 6.71429C0.0703125 5.86309 0.234003 5.05059 0.561384 4.27678C0.894717 3.49702 1.34115 2.82738 1.90067 2.26786C2.46615 1.70238 3.13579 1.25595 3.9096 0.928571C4.68936 0.595237 5.50484 0.428571 6.35603 0.428571C7.20722 0.428571 8.01972 0.595237 8.79353 0.928571C9.57329 1.25595 10.2429 1.70238 10.8025 2.26786C11.3679 2.82738 11.8144 3.49702 12.1417 4.27678C12.4751 5.05059 12.6417 5.86309 12.6417 6.71429C12.6417 8.02381 12.2727 9.21131 11.5346 10.2768L14.5971 13.3393C14.8173 13.5595 14.9275 13.8274 14.9275 14.1429Z" fill="white"/>
        </svg>

      </b-button>
    </b-input-group-append>

  </b-input-group>
</template>

<script>
export default {
  name: 'GallerySearchInput',
  data() {
    return {
      search: '',
    }
  },
  props:{
    onChange: Function,
  },
  mounted(){
    this.search = '';
  },
  watch:{
    search(newVal){
      this.$emit('on-change', newVal);
    }
  },
}
</script>

<style scoped lang="scss">

input {
  border-color: #929292 !important;
}
</style>
import AuthService from '../../services/AgentService';
import { showErrorMessage } from '@/utils/Notification';
import { isProd } from '@/services/EnvService';

// initial state
const state = () => ({
  agent: null,
  agentId: null,
  loading: false,
  permissions: {
    internal: false,
    internal_opt_out: false,
    dev: false,
    dev_opt_out: false,
    mls: false,
    company: false,
    office: false,
  },
});

// getters
const getters = {
  getAgent(state) {
    const { agent } = state;
    return agent;
  },
  getAgentId({ agentId }) {
    return agentId;
  },
  getAgentExternalId(state) {
    if (state.agentId) return state.agentId;

    if (state.agent && state.agent.ExternalId) {
      return state.agent.ExternalId;
    }

    // Removed part in order to test My MLS Galleries Tab
    // if (isUat() && process.env.VUE_APP_DEMO_EXT_ID) {
    //   return process.env.VUE_APP_DEMO_EXT_ID;
    // }

    return '';
  },
};

// actions
const actions = {
  async loadAgentData({ commit, dispatch }) {
    commit('setLoading', true);
    try {
      const agent = await AuthService.loadUserData();

      // Load agentId by Email
      const { Email: email } = agent;
      if (email) {
        const agentId = await AuthService.getAgentIdByEmail(email);
        console.log('Agent Id By Email', agentId);
        if (agentId === null && isProd()) {
          showErrorMessage('Cant get AgentId by Email');
        } else {
          commit('setAgentId', agentId);
        }
      }
      commit('setAgent', agent);
      dispatch('Auth/initAgentData', agent, { root: true });
    } catch (e) {
      console.error('agent error', e);
    } finally {
      commit('setLoading', false);
    }
  },

  /*
  async loadPermissions({ commit }) {
    commit('setLoading', true);
    try {
      const permissionsData = await AuthService.loadPermissions();
      if (permissionsData) {
        const permissions = {};
        permissions.office = permissionsData.office || false;
        permissions.mls = permissionsData.mls || false;
        permissions.company = permissionsData.company || false;

        const query = util.queryStringParse();

        if (query.pagt && query.pagt == 'yes') permissions.agent = true;
        if (query.poff && query.poff == 'yes') permissions.office = true;
        if (query.pcom && query.pcom == 'yes') permissions.company = true;
        if (query.pmls && query.pmls == 'yes') permissions.mls = true;
        if (query.pdev && query.pdev == 'yes') permissions.dev = true;
        if (query.pint && query.pint == 'yes') permissions.internal = true;

        commit('setPermissions', permissions);
      }
    } catch (e) {
      console.error('permissions err', e);
    } finally {
      commit('setLoading', false);
    }
  },

   */
};

// mutations
const mutations = {
  setAgent(state, newAgentData) {
    state.agent = newAgentData;
  },
  setAgentId(state, newAgentId) {
    state.agentId = newAgentId;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },
  setSampleLink(state, newLink) {
    state.sampleLink = newLink;
  },
  setPermissions(state, newPermissions) {
    state.permissions = { ...state.permissions, ...newPermissions };
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

import dbcapi, { inLocal } from '../../lib/dbcapi';
import { GALLERY_TYPE } from '@/services/GalleryService';

export const USER_ADMIN_PERMISSION = 'company';

export const OFFICE_ADMIN_PERMISSION = 'office';

const DefPermissions = { [GALLERY_TYPE.AG]: true, [GALLERY_TYPE.ML]: false, [GALLERY_TYPE.CO]: false, [GALLERY_TYPE.OF]: false };

// initial state
const state = () => ({
  authData: undefined,
  permissions: {...DefPermissions},
});


// getters
const getters = {
  getAgent(state) {
    const { authData } = state;
    return authData;
  },
  getPermissions(state) {
    const { permissions } = state;

    return permissions;
  },
}

// actions
const actions = {
  async loadPermissions({ commit }, permissionsData) {
    const permissions = {...DefPermissions};
    try {
      if (permissionsData) {
        // DMG-1496

        // MOMA: You can use AdminAccess as the Portal Admin Access flag, and
        // UserGroupAdmin as the User group admin flag

        // IF Admin Access - Portal AND Allow Gallery Upload are both checked/enabled, THEN the admin user has the ability to edit Office AND Company Galleries within the widget on the front-end
        if (permissionsData.AdminAccess && permissionsData.AllowGalleryUpload) {
          permissions[GALLERY_TYPE.OF] = true;
          permissions[GALLERY_TYPE.CO] = true;
        } else if (permissionsData.UserGroupAdmin && permissionsData.AllowGalleryUpload) {
          // IF Admin Access - User Group AND Allow Gallery Upload are both checked/enabled, THEN the admin user has the ability to ONLY edit Office Galleries within the widget on the front-end.
          permissions[GALLERY_TYPE.OF] = true;
        }
        // IF either Admin Access - Portal OR Admin Access - User Group are checked/enabled BUT Allow Gallery Upload is not, THEN the admin user cannot edit Office or Company Galleries on the front-end.
      }

      if (inLocal()) {
        permissions[GALLERY_TYPE.OF] = true;
        permissions[GALLERY_TYPE.CO] = true;
      }

      commit('setPermissions', permissions);
    } catch (e) {
      console.error('permissions error', e);
    }

  },
  async initAgentData({ commit, dispatch }, newAgent) {
    const authData = dbcapi.auth();
    if (newAgent) {
      authData.office = newAgent.office;
      authData.company = newAgent.company;
      commit('setAgent', authData);

      dispatch('Auth/loadPermissions', newAgent, { root: true });
    } else {
      commit('setAgent', authData);
    }
  },

};

// mutations
const mutations = {
  setAgent(state, newAgentData) {
    state.authData = newAgentData;
  },
  setLoading(state, status) {
    state.loading = status;
  },
  setSaveLoading(state, status) {
    state.saveLoading = status;
  },
  setPermissions(state, permissions) {
    state.permissions = permissions;
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
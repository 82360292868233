<template>
  <b-container>
    <b-row class="pt-1">
      <b-col class="gallery-container">
        <h2 class="text-center" v-if="!loading && (!list || !list.length)">
          {{ $t("No Galleries") }}
        </h2>


        <app-image-card
          v-for="e in listPage"
          :image="e.thumb || $options.defThumbUrl"
          :key="e.id"
          v-on:imageClicked="handleOpenGallery(e)"
        >
          <b-card-sub-title class="text-lines-2">
            <span class="align-middle sub-header"
              >{{ e.gallery_name }}
            </span>
          </b-card-sub-title>
          <b-card-text> </b-card-text>
        </app-image-card>
      </b-col>
    </b-row>
    <b-row v-if="totalCnt > itemsPerPage" class="mt-5">
      <b-col class="navigation-container">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalCnt"
          :per-page="itemsPerPage"
          aria-controls="my-table"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import AppImageCard from "@/components/AppImageCard";

export default {
  name: "GalleryListBox",
  components: { AppImageCard },
  data() {
    return {
      currentPage: 1,
    };
  },
  props: {
    list: {
      type: Array,
      default: function() {
        return [];
      },
    },
    type: String,
    isEditable: Boolean,
    isOnlySelect: Boolean,
    loading: Boolean,
    search: String,
  },
  defThumbUrl: process.env.VUE_APP_GALLERY_THUMB_URL,
  methods: {
    handleCreateNewClick() {
      this.$emit("onAddClick");
    },
    handleOpenGallery(g) {
      this.$store.commit('Galleries/setActiveGallery', g);
      if (!g.photos) {
        this.$store.dispatch("Galleries/loadGallery", {
          type: this.type,
          id: g.id
        });
      }
      /*
      this.$router.push({
        name: "single_gallery",
        params: { id, type: this.type },
      });

       */
    },
  },
  computed: {
    isAdmin() {
      return this.isEditable && !this.isOnlySelect;
    },
    itemsPerPage() {
      // return this.isAdmin ? ((+process.env.VUE_APP_PAGE_LIMIT) - 1) : +process.env.VUE_APP_PAGE_LIMIT;
      return +process.env.VUE_APP_PAGE_LIMIT;
    },
    totalCnt() {
      return this.list.length;
    },
    listPage() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.list.slice(start, end);
    },
  },
  watch: {
    search(){
      this.currentPage = 1;
    }
  }
};
</script>

<style scoped>
.gallery-container {
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 30%);
  justify-content: space-between;
  grid-gap: 0;
}

.gallery-wrap .card-body {
  padding: 0;
}

.gallery-card {
  position: relative;
  flex-basis: 30%;
  min-height: 250px;
  border: none;
  margin: 1rem 0 2rem !important;
  max-width: 450px;
  border: 1px solid var(--ButtonBackground, #333333);
  border-radius: 0 !important;
  padding: 10px;
}

.gallery-wrap .text-muted {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.8;
  margin-bottom: 10px;
}

.gallery-card img {
  min-height: 250px;
  margin-bottom: 20px;
  object-fit: cover;
  height: 255px;
}

.navigation-container {
  display: flex;
  justify-content: center;
}
</style>

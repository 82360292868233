<template>
  <div class="upload upload-modal">

    <div class="uploadArea" v-if="!uploading">
      <form @submit.prevent="cancelSubmit">
        <input id="gallery-file-upl" type="file" ref="uplInp" accept=".jpg, .pdf, .jpeg, .eps, .png" @change="clickUpload"
               class="inputfile"
               multiple>
        <label for="gallery-file-upl">
          <div class="dropzone-custom-content " @drop.prevent="addDraggedFile" @dragover.prevent>
            <!-- <h1 class="text-center color-dark">{{ $t('Add Photos ') }}</h1>   -->
            <!-- <span class="text-center color-dark">{{ $t('Upload up to {max} Images', { max: 20 }) }}</span> -->
            <h1 class="main-title">Add Photos</h1>
            <div class="w-100 d-flex justify-content-between align-items-center">
              <div>
                  <p class="upload-title text-left">Upload Files</p>
                  <p class="support-title">Supports: JPG, PNG, TIFF</p>
              </div>

              <b-button variant="primary" class="back-btn btn-primary" @click="goBack()">
                <span> {{ $t('Back To Gallery') }}</span>
              </b-button>
            </div>
            <div class="upload-btn-cont">
              
              <!-- <b-icon class="upload-icon" icon="download" /> -->

              <h5 class="upload-field-title">Drag & Drop to Upload</h5>
              <b-button variant="outline-primary" class="upload-btn" @click="$refs.uplInp.click()"><span >{{
                  $t('Browse files')
                }}</span></b-button>
            </div>
           
          </div>
        </label>
      </form>
    </div>

  </div>
</template>

<script>

export default {
  props: {
    gal: {
      type: Object,
      default() {
      },
    },
    uploading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    clickUpload(e) {
      this.$emit('uploading', { e, gal: this.gal });
    },
    cancelSubmit() {
      return false;
    },
    addDraggedFile(e) {
      // Transforms event into the expected event form.
      let filePayload = {target : {files : e.dataTransfer.files}};
      this.$emit('uploading', { e : filePayload, gal: this.gal });
    },
    goBack() {
      // this.$store.commit('Galleries/setActiveGallery', null);
      this.$store.commit('Main/setIsUploadPage', false);
    },
  },
}


</script>

<style lang="scss" scoped>
.upload {
  h3 {
    margin-top: 20px;
  }

  .uploadArea {
    // padding: 20px;
  }

  margin-bottom: 20px;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-size: 1.25em;
  background-color: white;
  display: block;
  cursor: pointer;
  text-align: center;
  color: gray;
  font-weight: normal;
  border-radius: 0.3rem;
}

#gallery-file-upl {
  + label {
    width: 735px !important;

    span:(last-child) {
      font-size: 18px;
    }
  }

  &:hover {
    background: transparent;
  }
}

.color-dark {
  color: rgb(58, 66, 94);
}

.dropzone-custom-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}


.upload-btn span {
  font-weight: 700;
  font-size: 16px;
  color: #296ED6;
}

.max-text {
  font-size: 16px;
  font-style: italic;
}

.upload-btn-cont {
    padding: 2rem;
    border: 3px dashed #296ED6;
    // margin: 0 1rem 2rem;
    width: 100%;
    border-radius: 0.3rem;
    padding-top: 102px;
    
    &:hover {
      background: transparent;
    }
}

.upload-icon {
    font-size: 40px;
    margin-bottom: 18px;
}

.upload-title {
  text-align: center;
}

.upload-title {
  font-size: 16px;
  margin-top: 1rem;
  margin-bottom: 0;
  color: #525151;
}

.support-title{ 
  font-size: 12px;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
  color: #525151;
}

.upload-field-title {
  font-size: 20px;
  color: #296ED6;
  font-weight: 700;
  margin-bottom: 10px ;
}

.dropzone-custom-content + p {
  font-size: 1rem;
}

.main-title {
    font-size: 36px;
    margin-bottom: 44px;
    color: #000F0C;
}

.upload-btn {
    margin: 20px;
    border:3px solid #296ED6 !important;
}

.back-btn {
  height: 38px;
  span {
     font-size: 18px;
     color:  #fff;
     
  }

}
</style>

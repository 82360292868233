<template>
  <b-modal
      :id="id"
      size="lg"
      title="Upload"
      @close="isShow=false"
      static
      v-model="isShow"
      header-bg-variant="secondary"
      header-text-variant="light"
      ok-title="Create"
      @ok="handleOk"
      hide-header-close
  >

    <template #default>

      <div>upload</div>


    </template>

    <template #modal-footer>
      <b-button variant="light" @click="isShow=false">Cancel</b-button>
      <b-button variant="primary" @click="handleOk">Add</b-button>
    </template>

  </b-modal>
</template>

<script>


export default {

  name: 'UploadImageModal',
  props: {
    id: String,
    show: Boolean,
    onClose: Function,
    onCreate: Function,
    loading: Boolean,
  },
  data() {
    return {
      // loading: false,
      isShow: false,


    }
  },

  computed: {},
  mounted() {

  },
  watch: {
    isShow(newValue) {
      if (!newValue) {
        this.$emit('onClose');
      }
    },
    show(newValue) {
      this.isShow = newValue;
    },
  },
  methods: {


    handleOk(bvModalEvent) {


      bvModalEvent.preventDefault()
    },
  },
}
</script>

<style>

</style>
<template>
  <b-container fluid class="p-0">
    <b-row class="search-input-cont">
      <b-col class="mt-4 mb-5">
        <SearchCreatePanel
            :is-content="!!(!loading && list && list.length)"
            :run-search="runSearch"
            :is-editable="isAdmin"
            :create-new="openNewGalleryWnd"
            :search="search"

        />
      </b-col>
    </b-row>

    <span class="text-center d-block mt-0">
      <div class="mt-5" v-if="loading || mlLoading">
        <b-spinner
            style="width: 4rem; height: 4rem; font-weight: bold; opacity: 0.5"
            v-if="loading || mlLoading"
        />
      </div>
    </span>

    <gallery-list-box
        :is-only-select="isOrderMode"
        :is-editable="isEditable"
        v-if="!loading && !mlLoading && list"
        :list="list"
        :type="type"
        @onAddClick="openNewGalleryWnd"
        :loading="loading || mlLoading"
        :search="search"
    />

    <new-gallery-modal
        id="new-gallery-wnd"
        :type="type"
        :show="isNewGalleryWndOpen"
        @onCreate="handleCreateGallery"
        @onClose="closeNewGalleryWnd"
        :loading="saveLoading"
    />
  </b-container>
</template>

<script>
import GalleryListBox from '@/components/GalleryListBox';
import { GALLERY_TYPE } from '@/services/GalleryService';
import { mapState } from 'vuex';
import NewGalleryModal from '@/components/NewGalleryModal';

import { i18n } from '@/lib/i8n';
import SearchCreatePanel from '@/components/SearchCreatePanel';

export default {
  name: 'MyGalleryPage',
  components: { SearchCreatePanel, NewGalleryModal, GalleryListBox },
  gType: GALLERY_TYPE,
  props: {
    type: {
      type: String,
      default: GALLERY_TYPE.AG,
    },
  },
  data() {
    return {
      list: null,
      isNewGalleryWndOpen: false,
      search: '',
    };
  },
  computed: {
    ...mapState({
                  activeList: state => state.Galleries.activeList,
                  activeGalleryId: state => state.Galleries.activeGalleryId,
                  isOrderMode: state => state.Main.isOrderMode,
                  loading: state => state.Galleries.loading,
                  mlLoading: state => state.Galleries.mlLoading,
                  saveLoading: state => state.Galleries.saveLoading,
                  user: state => state.Auth.authData,
                  permissions: state => state.Auth.permissions,
                }),
    isEditable() {
      return !!this.permissions[this.type];
    },
    isAdmin() {
      return this.isEditable && !this.isOrderMode;
    },
  },
  mounted() {
    this.list = this.activeList;
    if (this.user) {
      // disabled default loading
      this.$store.dispatch(
          'Galleries/loadGalleriesByType',
          { type: this.type },
          { root: true },
      );
    }
  },
  beforeDestroy() {
    this.list = null;
  },
  watch: {
    activeList(newList) {
      this.list = newList;
    },
    async type(newType) {
      await this.$store.dispatch(
          'Galleries/loadGalleriesByType',
          { type: newType },
          { root: true },
      );
    },
    async activeGalleryId(){
      await this.$store.dispatch(
          'Galleries/loadGalleriesByType',
          { type: this.type },
          { root: true },
      );
    },
    async user() {
      this.$store.dispatch(
          'Galleries/loadGalleriesByType',
          { type: this.type },
          { root: true },
      );
    },
  },
  methods: {
    runSearch(newSearchStr) {
      this.search = newSearchStr;
      if (newSearchStr.length === 0) {
        this.list = this.activeList;
      } else {
        if (Array.isArray(this.activeList)) {
          this.list = this.activeList.filter(_ =>
                                                 _.gallery_name.toLowerCase().includes(newSearchStr.toLowerCase()),
          );
        }
      }
    },
    openNewGalleryWnd() {
      this.isNewGalleryWndOpen = true;
    },
    closeNewGalleryWnd() {
      this.isNewGalleryWndOpen = false;
    },
    async handleCreateGallery(newGallery) {
      try {
        newGallery.gallery_type = this.type;
        await this.$store.dispatch('Galleries/createGallery', newGallery, {
          root: true,
        });
        this.isNewGalleryWndOpen = false;
        this.$toast.success(this.$t('New Gallery was added'));
      } catch (e) {
        this.$toast.error(i18n.t('New Gallery creation Error'));
      }
    },
  },
};
</script>

<style>
.gallery-card {
  border-radius: 4px;
  background: #fff;
  /* box-shadow: 0 6px 10px rgba(0, 0, 0, .08), 0 0 6px rgba(0, 0, 0, .05); */
  transition: 0.3s transform cubic-bezier(0.155, 1.105, 0.295, 1.12),
  0.3s box-shadow,
  0.3s -webkit-transform cubic-bezier(0.155, 1.105, 0.295, 1.12);
  /*padding: 14px 80px 18px 36px;*/
  cursor: pointer;
}

.gallery-card:hover {
  transform: scale(1.05);
  /* box-shadow: 0 10px 20px rgba(0, 0, 0, .12), 0 4px 8px rgba(0, 0, 0, .06); */
}

.gallery-card h3 {
  font-weight: 600;
}

.gallery-card img {
  height: 150px;
}

.search-input-cont {
  position: absolute;
  top:-15px;
  right: 0;
}
</style>

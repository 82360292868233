import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import vClickOutside from "v-click-outside";
import Vuelidate from "vuelidate";
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import VueI18n from "vue-i18n";

window.GalleryVue = Vue
// here
window.GalleryVue.use(VueI18n);

import { i18n } from "./lib/i8n";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { mapState } from "vuex";
import { GALLERY_TYPE } from "@/services/GalleryService";

window.GalleryVue.use(vClickOutside);
// Install BootstrapVue
window.GalleryVue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
window.GalleryVue.use(IconsPlugin);

window.GalleryVue.use(Vuelidate);

window.GalleryVue.use(Toast, {});

// devtools was moved to vuex init store file

var GalleryWidget = new Vue({
  el: "#gallery_app",
  i18n,
 // router,
  store,
  render: h => h(App, { props: { title: "222" } }),
  // components: {App},
  computed: {
    ...mapState({
      user: state => state.Auth.authData
    })
  },
  async beforeCreate() {
    await store.dispatch("Agent/loadAgentData", null, { root: true });
    // await this.$store.dispatch('Auth/loadPermissions', null, { root: true });
  },
  watch: {
    async user() {
      // await this.$store.dispatch('Auth/loadPermissions', null, { root: true });
    }
  },
  mounted(){
  },
  methods: {
    /*
    @params:
    orderId: optional, currently not used,
    listId: Mls listId|Gallery id,
    tab: gallery type GALLERY_TYPE: ML-mls,AG-custom,OF-office, CO-company
    selectedPhotoId: selected photo id("pk" attribute from getSelectedImages())

     */
    setOrderMode(orderId, listId, tab, selectedPhotoId) {
      console.log('Gallery set order mode',orderId, listId, tab, selectedPhotoId);
      this.$store.dispatch(
        "Main/setOrderMode",
        { orderId, listId },
        { root: true }
      );
      this.$store.commit('Galleries/setActiveType', tab || GALLERY_TYPE.ML);

      if (listId) {
        // this.$store.commit('Galleries/setActiveGalleryId', listId);
        this.$store.dispatch('Galleries/loadGallery', {type: tab || GALLERY_TYPE.ML, id: listId, selectedPhotoId});

        //if (selectedPhotoId){
        //  this.$store.commit('ImageSelection/setSelectedId', selectedPhotoId);
       // }

      } else if (tab) {
        // GALLERY_TYPE
        // this.$store.commit("Galleries/setActiveType", tab, { root: true });
        this.$store.commit("Galleries/setActiveType", tab);
        // this.$router.push({ name: "Galleries" }).catch(() => {});
      } else {
        // suppressed error on redirect to the current location
        // this.$router.push({ name: "Galleries" }).catch(() => {});
      }
    },
    getOrderData() {
      return this.$store.getters["Main/getOrderData"];
    },
    getSelectedImages() {
      return this.$store.getters["ImageSelection/getSelectedImages"];
    },
    getSelectedGallery() {
      return this.$store.getters["Galleries/getActiveGalleryAndType"];
    }
  }
}).$mount("#gallery_app");

window.GalleryWidget = GalleryWidget;

export default GalleryWidget;

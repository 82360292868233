export const EnvType = {
  PRODUCTION: 'production',
  UAT: 'uat',
}

export const getEnv = () => {
  return process.env.NODE_ENV;
}

export const isUat = () => {
  return getEnv() === EnvType.UAT;
}

export const isProd = () => {
  return getEnv() === EnvType.PRODUCTION;
}

export default {};
<template>
  <div id="gallery_app" class="my-gallery-main-wrapper container gallery-wrap">
    <theme-style wrapper-prefix="my-gallery-main-wrapper" />
    <MainContainer />
  </div>
</template>

<script>

import store from './store'
import ThemeStyle from './components/ThemeStyle';
import MainContainer from './containers/MainContainer';

export default {
  store,
  name: 'app',
  props: ['title'],
  components: { ThemeStyle, MainContainer },
  data() {
    return {
      use_router: false,
    }
  },
}
</script>

<style lang="scss">

@font-face {
  font-family: 'Metro-Sans';
  src: url("/fonts/my-gallery-widget/Metro-Sans-Regular.eot");
  src: url("/fonts/my-gallery-widget/Metro-Sans-Regular.eot") format("embedded-opentype"), url("/fonts/my-gallery-widget/Metro-Sans-Regular.ttf") format("truetype"), url("/fonts/my-gallery-widget/Metro-Sans-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.my-gallery-main-wrapper, .modal {
  @import "./scss/custom.scss";

}

.my-gallery-main-wrapper {
  @import "./custom.scss";

}




.my-gallery-main-wrapper {

  a {
    font-size: 1.33rem;
    font-weight: 700;
    line-height: 19px;
  }

  h1 {
    font-size: 36px;
    font-weight: 700;
  }
}

.my-gallery-main-wrapper .nav-tabs .nav-link {
  border: none;
  padding: 0.8rem 1.2rem;
}

html {
  font-size: 12px;
}


</style>

<style lang="scss">

#gallery_app {
  font-family: "Metro-Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: var(--BodyTextColor, #2c3e50);
  margin-top: 50px;
  font-size: 16px !important;
}

.logged-in {
  text-align: initial;
}

#nav {
  padding: 30px;
}

#nav li a {
  font-weight: bold;
}

.gallery-wrap a {
  color: var(--MainHeaders, #00204F);
}

/*
#nav a.router-link-exact-active {
  color: #fff;
  background: #A83535;
}


.gallery-wrap .nav-tabs .nav-link.active, .gallery-wrap .nav-tabs .nav-item.show .nav-link {
   color: #fff;
  background: #A83535;
}

 */

.gallery-wrap .nav-tabs .nav-link.active, .gallery-wrap .nav-tabs .nav-item.show .nav-link {
  font-size: 1.33rem;
}

.gallery-tabs li a {
  font-family: "Metro-Sans" !important;
}

.page-item, .page-item button, .page-item span {
  width: 38px !important;
  height: 38px !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  margin: 1px;
  color: #3A425E !important;
}

.gallery-wrap .page-item.active .page-link {
  color: #fff !important;
}

@media (min-width: 1200px) {
  .gallery-wrap .container-xl, .gallery-wrap .container-lg, .gallery-wrap .container-md, .gallery-wrap .container-sm, .gallery-wrap .container {
    max-width: 100% !important;
  }
}

@media (min-width: 576px) {
  .gallery-wrap .modal-dialog {
    max-width: 380px !important;

    .modal-content {
      padding: 18px 26px 27px 26px;
    }
  }
}

.gallery-wrap .m-2 {
  // margin: 1.2rem !important;
  margin: 0 !important;
  padding: 0 !important;
}

.gallery-wrap hr {
  margin-top: 0 !important;
}

.new-card-wrap .card-body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-footer .btn + .btn {
  margin-bottom: auto !important;
}

h5 {
  color: #2c3e50;
}

.Vue-Toastification__toast--success {
  background: #fff !important;
  color: green !important;
  border: 1px solid green !important;
}

.Vue-Toastification__toast--error {
  background: #fff !important;
  color: red !important;
  border: 1px solid red !important;
}

.my-gallery-main-wrapper .modal-header {
    border-bottom: none;
}

.btn-light {
  border: 2px solid var(--ButtonBackground, #00204F) !important;
  height: 38px !important;
  background: transparent !important;
  font-size: 16px;
}

.btn-dark {
  height: 38px !important;
  font-size: 16px;
  background: var(--ButtonBackground, #00204F) !important;
}

.progress .progress-bar{
  background: rgba(128,128,128,0.5) !important;
} 

</style>


// import { Storage } from 'aws-amplify';
import dbcapi from './dbcapi';
import awsapi from './dynamodb';
import util from './util';
import axios from 'axios';
import { showErrorMessage } from '@/utils/Notification';
// import awsconfig from '../aws-exports';

/*

Upload.vue "uploading" {e,gal} >
Gallery.vue "uploading" isUploading {e,gal} >
GalleryApp.vue "uploading" uploading
    upload.doUploads(arg.e, arg.gal);
        checkUpload(e, gal)
        upload.getCompletedNeedingSave();
            upload.updateSaved(saveThese[i],true);
            upload.uploadSave(ph, g, sess);

            
https://github.com/jbesw/askjames-s3uploader/blob/master/src/components/s3uploader.vue

    {
        file: File
        remote: String [s3 filename and array key]
        progress: Int
        i :Int
        status: String [pending|progress|complete|error]
        saved: Boolean [if status complete and not saved, gets dynamod]
    }
*/
let uploadsInProgress = {};

const debug = () => {
    let arr = [];
    for (let i in uploadsInProgress) {
        arr.push({ i, t: uploadsInProgress[i].status });
    }
    return arr;
}

// get active uploads
const uploadQueue = () => {
    let incomplete = 0;
    let total = 0;
    let progress = 0;
    let errors = 0;
    for (let i in uploadsInProgress) {
        // console.log('process update data', uploadsInProgress[i]);
        switch (uploadsInProgress[i].status) {
            case 'complete':
                break;
            case 'progress':
            case 'pending':
                incomplete++;
                total += 100;
                progress += uploadsInProgress[i].progress;
                break;
            case 'error':
                errors++;
                break;
            default:
        }
    }
    if (progress < 10) progress = 10; // dont div by zero
    if (total < 10) total = 10; // dont div by zero
    progress = Math.ceil((progress / total) * 100);
    return {incomplete, progress, errors};
}

const updateSaved = (file, saved) => {
    uploadsInProgress[file.remote].saved = saved;
}

const getCompletedNeedingSave = () => {
    let unsaved = [];
    for (let i in uploadsInProgress) {
        if (uploadsInProgress[i].status == 'complete' && !uploadsInProgress[i].saved) {
            unsaved.push(uploadsInProgress[i]);
        }
    }
    return unsaved;
}

const updateProgress = (filename, progress, error) => {
    uploadsInProgress[filename].progress = progress;
    if (progress == 100) {
        uploadsInProgress[filename].status = 'complete';
        uploadsInProgress[filename].saved = false;
    } else if (error) {
        uploadsInProgress[filename].status = 'error';
    } else {
        uploadsInProgress[filename].status = 'progress';
    }
    return uploadQueue();
}

// validate for doUploads()
// add to uploadsInProgress, including making file name
// return quantity of valid submitted files
const checkUpload = (e, gal) => {
    if (!e.target.files) return false;
    const files = e.target.files;
    // const file1 = files[0];
    // const file = files[0].name;
    // let files_a = [];
    let remote = '';
    let validFiles = 0;
    let status = 'pending';
    let errorMessage = '';
    let fileType = '';
    let fileName = '';
    let guid = '';
    let fileSize = 0;
    const maxSizeJPG = (1024 * 1000 * 10);
    const maxSizePDF = (1024 * 1000 * 30);
    const currGal = Object.assign( {}, gal);
    if (!files || files.length == 0) {
        console.log('no files');
        return 0;
    }
    for (let f=0; f < files.length; f++) {
        console.log('f',f);
        guid = util.guid();
        fileType = files[f].type;
        fileName = files[f].name;
        fileSize = files[f].size;
        console.log(`${fileName} is a ${fileType} of ${fileSize} bytes`);
        switch (fileType) {
            case 'image/jpeg':
                remote = guid + '.jpg';
                if (fileSize > maxSizeJPG) {
                    status = 'error';
                    errorMessage = 'Maximum JPG size is 10MB.';
					showErrorMessage(errorMessage);
                } else {
                    validFiles++;
                }
                break;
            case 'image/png':
                remote = guid + '.png';
                if (fileSize > maxSizeJPG) {
                    status = 'error';
                    errorMessage = 'Maximum PNG size is 10MB.';
					showErrorMessage(errorMessage);
                } else {
                    validFiles++;
                }
                break;
            case 'application/pdf':
                remote = guid + '.pdf';
                if (fileSize > maxSizePDF) {
                    status = 'error';
                    errorMessage = 'Maximum PDF size is 30MB.';
					showErrorMessage(errorMessage);
                } else {
                    validFiles++;
                }
                break;
            case 'application/postscript':
                remote = guid + '.eps';
                if (fileSize > maxSizePDF) {
                    status = 'error';
                    errorMessage = 'Maximum EPS size is 30MB.';
					showErrorMessage(errorMessage);
                } else {
                    validFiles++;
                }
                break;
            default:
                remote = guid + '.xxx';
                status = 'error';
                errorMessage = `Unsupported format ${fileName} ${fileType}`;
				showErrorMessage(errorMessage);
        }

        if (remote!='') {
            uploadsInProgress[remote] = { remote, i: f, file: files[f], progress: 0, status, errorMessage, saved: false, gal: currGal};
        }
    }
    console.log('uploadsInProgress',uploadsInProgress);
    return validFiles;
}





// const doUpload = (filename,fileContents) => {
//     if (!filename || !fileContents) {
//         console.log('doUpload called with empty filename or contents');
//         return;
//     }
//     Storage.put(filename, fileContents, {
//         // contentType: 'image/jpeg',
//         ACL: "public-read",
//         level: 'public',
//         progressCallback(progress) {
//             const n = Math.round((progress.loaded / progress.total) * 100);
//             if (n > 95) {
//                 updateProgress(filename, 100);
//             } else {
//                 updateProgress(filename, n);
//             }
//         }
//     })
//     .catch(err => {
//         console.log('file upl error', err);
//         updateProgress(filename, 0, err);
//     });
// };

let authData = dbcapi.auth();

const headers = {
    'Content-Type': 'application/json',
    'x-api-key': authData.gatewayToken,
    'Authorization': 'Bearer ' + authData.token
};

// TODO keep building this
/*

Request
{
    gallery_id
    company,office,agent
    file
    filename
}

Response
{
    id
    sort
    src (url)
}

*/

const usePresignedUrl = (presignedUrl, file, cb) => {
    axios({
        method : "PUT",
        url : presignedUrl,
        data : file,
		headers : {
			'Content-Type': file.type,
			'x-amz-acl' : 'public-read',
			'x-amz-meta-src' : 'TRUE'
		}
    }).then(res => {
        return cb(undefined, res.data)
    }).catch(e => {
        return cb(e);
    });
};

const doUpload2 = (filename,fileContents) =>{
    if (!filename || !fileContents) {
        console.log('doUpload called with empty filename or contents');
        return;
    }
    axios({
		method : "POST",
		url : authData.gateway + '/gallery/upload',
		data : {
			filename : filename,
			mimetype : fileContents.type
		},
		headers
	}).then(res => {
        console.log('upload.doUpload2 complete, using presigned URL',filename);

        usePresignedUrl(res.data.presignedUrl, fileContents, (e, s3_res) => {
            if (e) {
                console.log('usePresignedUrl error',e);
                return updateProgress(filename, 0, e);
            }
            updateProgress(filename, 100);

            console.log('usePresignedUrl complete',filename, s3_res);
        });

    }).catch((e)=>{
        console.log('upload.doUpload2 error',e);
        updateProgress(filename, 0, e);
    });
};

const doUploads = (e, gal) => {
    var uploads = checkUpload(e, gal);
    if (!uploads) return false;
    for (let i in uploadsInProgress) {
        // console.log(i);
        if (uploadsInProgress[i].status == 'pending') {
            doUpload2(uploadsInProgress[i].remote, uploadsInProgress[i].file);
        }
    }
    return true;
};

const uploadSave = (arg, activeGal, sess) => {
    // console.log('uploadSave what is arg.remote ?', arg);
    /*
    created_at: "20200924"
height: 1
pk: "AG-gallery-66e9a9d2-2b5d-4c89-8029-a6f1e55bc9d8"
sk: "photo-20200924-ac81f13c-7c05-487b-a71e-2e541bee73ee.jpg"
source_system: "Upload"
src: "https://galleryfiles-devickle143912-dev.s3.amazonaws.com/public/ac81f13c-7c05-487b-a71e-2e541bee73ee.jpg"
thumb: ""
width: 1
    */
    // const g = activeGal;
    // const dt = awsapi.dateStamp();
    const ph = {
        created_at: arg.created_at,
        height: 1,
        width: 1,
        thumb: '',
        // pk: g.gallery_type + '-' + g.id,
        // sk: 'photo-' + dt + '-' + arg.remote,
        source_system: arg.source_system,
        pk: arg.pk,
        sk: arg.sk,
        src: arg.src
        // src: 'https://' + awsconfig.aws_user_files_s3_bucket + '.s3.amazonaws.com/public/' + arg.remote
    };
    const ph2 = util.photo(ph, sess);
    // console.log('uploadSave debug save paused', ph, ph2);
    awsapi.resetCache();
    awsapi.insertData( ph2 );
    return ph2;
}

const uploadSaveAsync = async (arg, activeGal, sess) => {
    // console.log('uploadSave what is arg.remote ?', arg);
    /*
    created_at: "20200924"
height: 1
pk: "AG-gallery-66e9a9d2-2b5d-4c89-8029-a6f1e55bc9d8"
sk: "photo-20200924-ac81f13c-7c05-487b-a71e-2e541bee73ee.jpg"
source_system: "Upload"
src: "https://galleryfiles-devickle143912-dev.s3.amazonaws.com/public/ac81f13c-7c05-487b-a71e-2e541bee73ee.jpg"
thumb: ""
width: 1
    */
    // const g = activeGal;
    // const dt = awsapi.dateStamp();
    const ph = {
        created_at: arg.created_at,
        height: 1,
        width: 1,
        thumb: '',
        // pk: g.gallery_type + '-' + g.id,
        // sk: 'photo-' + dt + '-' + arg.remote,
        source_system: arg.source_system,
        pk: arg.pk,
        sk: arg.sk,
        src: arg.src
        // src: 'https://' + awsconfig.aws_user_files_s3_bucket + '.s3.amazonaws.com/public/' + arg.remote
    };
    const ph2 = util.photo(ph, sess);
    console.log('++++ uploadSave debug save paused', ph, ph2);
    awsapi.resetCache();
    await awsapi.insertDataPromisified( ph2 );
    return ph2;
}


export default {
    doUploads,
    doUpload2,
    uploadQueue,
    getCompletedNeedingSave,
    updateSaved,
    uploadSave,
    debug,
    uploadSaveAsync
}

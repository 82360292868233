<template>
  <b-tabs
    content-class="mt-3"
    nav-class="gallery-tabs"
    style="padding-bottom: 1px; position: relative;"
    active-tab-class="font-weight-bold"
  >
    <b-tab
      :key="$options.gType.AG"
      :title="$t('My Gallery')"
      :active="!type || type === $options.gType.AG"
      lazy
      @click="handleChangeType($options.gType.AG)"
    >
      <gallery-page :type="$options.gType.AG" />
    </b-tab>
    <b-tab
      :key="$options.gType.OF"
      :title="$t('Office Gallery')"
      :active="type === $options.gType.OF"
      lazy
      @click="handleChangeType($options.gType.OF)"
    >
      <gallery-page :type="$options.gType.OF" />
    </b-tab>
    <b-tab
      :key="$options.gType.CO"
      :title="$t('Company Gallery')"
      :active="type === $options.gType.CO"
      lazy
      @click="handleChangeType($options.gType.CO)"
    >
      <gallery-page :type="$options.gType.CO" />
    </b-tab>
    <b-tab
      v-if="getAgentExternalId"
      :key="$options.gType.ML"
      :title="$t('My MLS Gallery')"
      lazy
      :active="type === $options.gType.ML"
      @click="handleChangeType($options.gType.ML)"
    >
    
      <gallery-page :type="$options.gType.ML" />
    </b-tab>
    <!--
    <template #tabs-end>
      <div style="position: absolute; right: 0; top: -10px;">
        <gallery-search-input @on-change="runSearch" />
      </div>
    </template>
    -->

  </b-tabs>
</template>

<script>
import GalleryPage from "@/views/GalleriesPage";
import { GALLERY_TYPE } from "@/services/GalleryService";
import { mapGetters } from "vuex";

export default {
  name: "GalleryPageTabs",
  components: { GalleryPage },
  methods: {
    handleChangeType(selectedType) {
      this.$store.commit("Galleries/setActiveType", selectedType);
    },
  },
  computed: {
    ...mapGetters({
      getAgentExternalId: "Agent/getAgentExternalId",
    }),
  },
  props: {
    type: String
  },
  gType: GALLERY_TYPE,
  watch: {}
};
</script>

<style scoped></style>

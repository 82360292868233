<template>
  <b-modal
      :id="id"
      size="xl"
      :title="title"
      @close="isShow=false"
      static
      v-model="isShow"
      header-bg-variant=""
      header-text-variant=""
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      @ok="handleOk"
      hide-header-close
      header-class="modal-header-left"
  >

    <slot name="default"></slot>


    <template #modal-footer>
      <b-button variant="light" @click="isShow=false" :disabled="loading">{{ $t('Cancel') }}</b-button>
      <b-button variant="primary" @click="handleOk" :disabled="loading">
        <b-spinner small v-if="loading" /><span  v-if="!loading">{{ $t('Confirm') }}</span></b-button>
    </template>


  </b-modal>
</template>

<script>

export default {
  name: 'DeleteModal',
  props: {
    id: String,
    deleteId: Object,
    show: Boolean,
    onClose: Function,
    onOk: Function,
    title: String,
    text: Object,
    loading: Boolean,
  },
  data() {
    return {
      isShow: false,
    }
  },
  watch: {
    isShow(newValue) {
      if (!newValue) {
        this.$emit('on-close');
      }
    },
    show(newValue) {
      this.isShow = newValue;
    },
  },
  methods: {
    handleOk(){ // bvModalEvent
        this.$emit('on-ok', this.deleteId);
      // bvModalEvent.preventDefault()
    },
  }
}
</script>

<style>

</style>
<template>
  <b-container fluid="">
    <!-- <h1 class="">
      {{ $t('Add Photos') }}

    </h1> -->

    <!-- <b-row>
      <b-col class="mt-4 mb-5">
        <b-button class="mr-4 back-btn pl-3 pr-3" @click="goBack()">
          <b-icon icon="arrow-left-circle-fill" />
          <span> {{ $t('Back To Gallery') }}</span>
        </b-button>
      </b-col>

    </b-row> -->

    <b-row>
      <b-col>
        <h2>{{ activeGallery.name || '' }}</h2>
      </b-col>

    </b-row>

    <b-container class="p-0">
      <b-row>
        <b-col>

          <div class="uploadbar pt-5" v-if="uploading">
            <b-progress id="progress" max=100 height="2rem">

              <b-progress-bar :value="value < 80 ? value : 80" variant="primary" animated>

                <span
                    v-if="value>10 && value< 80">{{ $t('Uploading') }}...</span>

                <span
                    v-if="value >= 80 && value < 90">{{ $t('Thumbs Creating') }}...</span>

                <span
                    v-if="value >=90 && value <= 100">{{ $t('Reloading') }}...</span>
              </b-progress-bar>
              <b-progress-bar :value="value >= 80 && value <=90? (value - 80) : (value < 80 ? 0 : 10)" variant="warning"
                              animated>
              </b-progress-bar>

              <b-progress-bar :value="value >90 ? (value - 90) : 0" variant="success" animated></b-progress-bar>

            </b-progress>
          </div>

          <Upload v-if="!uploading" :value="value" :langpref="langpref" :gal="activeGallery" @uploading="uploadFileNew"
                  :uploading="uploading" />


          <!--
                  <vue-dropzone
                      ref="myVueDropzone"
                      id="dropzone"
                      :options="dropzoneOptions"
                      :useCustomSlot=true
                      class="bg-light"
                      @vdropzone-error="handleError"
                      v-on:vdropzone-file-added="setFile"
                      v-on:vdropzone-success="uploadSuccess"
                  >
                    <div class="dropzone-custom-content">
                      <h3>Upload up to 20 Images</h3><br />
                      <b-button variant="primary" pill><span class="font-weight-bold">Upload</span></b-button><br/><br/>
                      <h4>Click to Add Images</h4>
                      <small>Limit file sizes to 10MB</small>
                    </div>

                  </vue-dropzone>

          -->

        </b-col>
      </b-row>
    </b-container>


  </b-container>
</template>

<script>

import { mapState } from 'vuex';

// import vue2Dropzone from 'vue2-dropzone'
// import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import Upload from '../components/Upload';
import upload from '@/lib/upload';
import util from '@/lib/util';
import dbcapi from '@/lib/dbcapi';



export default {
  name: 'UploadImagesPage',
  components: {
    // vueDropzone: vue2Dropzone,
    Upload,
  },
  params: {},
  computed: {
    ...mapState({
                  activeGallery: state => state.Galleries.activeGallery || {},
                  agent: state => state.authData || {},
                  loading: state => state.Galleries.loading,
                  langpref: state => state.Main.lang,
                  user: state => state.Auth.authData,
                }),
  },
  data() {
    return {
      /*
      dropzoneOptions: {
        url: '/',
        thumbnailWidth: 150,
        autoProcessQueue: true,
        maxFilesize: 10,
        maxFiles: 20,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: 'image/*',
      },
      files: null,
      */
      uploading: false,
      open: false,
      value: 0,
    }
  },
  mounted() {
    this.type = this.$route.params.type;
    this.id = this.$route.params.id;
    if (this.user && (!this.activeGallery || this.activeGallery.id !== this.id)) {
      this.$store.dispatch('Galleries/loadGallery', { type: this.type, id: this.id });
    }
    this.files = null;
    // this.$refs.myVueDropzone.removeAllFiles();
  },
  methods: {
    goBack() {
      // this.$store.commit('Galleries/setActiveGallery', null);
      this.$store.commit('Main/setIsUploadPage', false);
    },
    async uploadFileNew(e) {
      const arg = {
        ...e,
        gal: this.activeGallery,
      }
      const filesCnt = arg.e.target.files.length;

      console.log('GA isUploading', arg, filesCnt);

      if (filesCnt > 20) {
        this.$toast.error(this.$t('You cannot upload more then {max} files', { max: 20 }));
        return;
      }

      // validate size
      const errorFileNames = [];
      arg.e.target.files.forEach(f => {
        const sizeMB = f.size/1024/1024;
        if (sizeMB >= +(process.env.VUE_APP_FILE_SIZE_LIMIT_MB || 11)){
          errorFileNames.push(f.name);
          delete arg.e.target.files[f]
        }
      });

      if (errorFileNames.length){
        errorFileNames.forEach(fName => this.$toast.error(this.$t('{f_name} exceeds the size limit of 10MB', { f_name: fName })));
        document.getElementById('gallery-file-upl').value = '';
        return;
      }

      this.uploading = true;
      upload.doUploads(arg.e, arg.gal);
      let t = this;
      // const sess = this.sessionData();
      const dt = util.dateStamp();
      const authData = dbcapi.auth();
      const fileEntities = [];
      const checkUpload = async () => {
        const q = upload.uploadQueue();
        let el = document.getElementById('progress');
        if (el) {
          console.log('update progress', q.progress, q);
          el.value = q.progress;
          t.value = t.value < 80 ? t.value + q.progress / filesCnt : 80;
          t.value = t.value > 80 ? 80 : t.value;
        }

        const saveThese = upload.getCompletedNeedingSave();

        let g = null;
        let ph = null;

        for (let i in saveThese) {
          console.log('saveThese[i] expects gal', saveThese[i]);
          upload.updateSaved(saveThese[i], true); // just go ahead and mark it, maybe improve later
          // t.uploadedFile(saveThese[i]);
          g = saveThese[i].gal;

          ph = {
            created_at: dt,
            height: 1,
            width: 1,
            thumb: '',
            pk: g.gallery_type + '-' + g.id,
            sk: 'photo-' + dt + '-' + saveThese[i].remote,
            source_system: 'Upload',
            src: 'https://' + authData.galleryBucket + '.s3.amazonaws.com/public/' + saveThese[i].remote,
          };
          /*
          for (let j = 0; j < this.galleries[g.gallery_type].length; j++) {
            if (this.galleries[g.gallery_type][j].id == this.active_gallery.id) {
              this.galleries[g.gallery_type][j].photos.push(Object.assign({}, ph));
            }
          }
          */
          await upload.uploadSaveAsync(ph, g, {
            agent: this.agent.agent,
            office: this.agent.office,
            company: this.agent.company,
          });

          fileEntities.push(ph);
        }

        if (q.incomplete == 0 && t.uploading) {
          t.value = 80;

          const afterThumb = async () => {
            // When a new image is uploaded, use the image itself as the thumbnail.
            if (!('thumb' in this.activeGallery) || !this.activeGallery.thumb) {
              const fImgUrl = fileEntities.length ? fileEntities[0].src : undefined;

              if (fImgUrl) {
                // For PDFs, since we cannot fall back on the src image we must use pre-generated thumb URL.
                if (fImgUrl.includes(".pdf")) {
                  const pdfThumbUrl = fImgUrl.replace(".pdf", "-thumb.png")
                  await this.$store.dispatch('Galleries/updateActiveGalleryThumb', pdfThumbUrl);
                } else {
                  await this.$store.dispatch('Galleries/updateActiveGalleryThumb', fImgUrl);
                }
              }
            }

            t.value = 90;

            t.value = 95;
            await this.$store.dispatch('Galleries/loadGallery',
                                      { type: this.activeGallery.gallery_type, id: this.activeGallery.id });

            t.value = 100;
            setTimeout(() => {
              this.$store.dispatch('Galleries/loadGalleriesByType',
                                         { type: this.activeGallery.gallery_type, isReload: true }).then();
              t.uploading = false;
              this.goBack();
            }, 500)
          }

          let completedEntities = 0;
          fileEntities.forEach((_) => {
            this.$store.dispatch('Galleries/createThumb', _).then((r) => {
                console.log("createThumb success", r);
                if (t.value < 90) t.value += Math.round(10 / fileEntities.length);
            }).catch((e) => {
                console.error("createThumb success", e);
                if (t.value < 90) t.value += Math.round(10 / fileEntities.length);
            }).finally(() => {
              completedEntities += 1;
              // If all files have been processed and the first image is a PDF, then we need to wait until thumb is finished generating.
              if (completedEntities == fileEntities.length && fileEntities[0].src.includes(".pdf")) afterThumb();
            });
          });
          // If the first file is not a PDF, we can proceed for faster load times.
          if (fileEntities.length && !fileEntities[0].src.includes(".pdf")) afterThumb();
          
        } else {
          setTimeout(() => {
            checkUpload();
            // t.uploadedFile({remote});
            // t.$emit('uploadedfile', {file, remote});
            // document.getElementById('file').value = '';
          }, 500);
        }
      }

      setTimeout(() => {
        checkUpload();
      }, 500);
    },
    uploadFile() { // files
      // this.$emit('save', this.item.id, files);
      return true;
    },
    setFile(files) {
      this.files = files;
    },
    handleError(file, message) {
      this.$toast.error(message);

      if (file.status === 'error') {
        /*
        showErrorMessage('Invalid file or file size has exceeded it max limit of 5MB. Please try another one.');
        const cFiles = this.$refs.myVueDropzone.getRejectedFiles();
        if (cFiles.length === 1) {
          this.$refs.myVueDropzone.removeFile(file);
        }

         */
        // this.files = [];
      }
    },
    uploadSuccess() {
      this.$toast.success(this.$t('Images were Added successfully'));
    },
  },
  watch: {
    user() {
      if (!this.activeGallery || this.activeGallery.id !== this.id) {
        this.$store.dispatch('Galleries/loadGallery', { type: this.type, id: this.id });
      }
    },
  },
  directives: {},
}
</script>

<style scoped>


.back-btn span{
  font-size: 1.25rem;
}

</style>